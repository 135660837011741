@media (min-width:1500px){

}
@media(max-width:2400px){
	.slide .slide__img img{width:100%;object-fit:cover;}
}
@media(max-width:1640px){
	html{font-size:62%;}
	.slide .slide__img img{width:100%;object-fit:cover;}
}
@media(max-width:1499px){

}
@media(max-width:1399px){
	.mvpWrap .rightDataWrap ul li .itemWrap .icoWrap{width:100px;height:100px;top:-138px}
	.mvpWrap .rightDataWrap ul li:nth-child(1) .itemWrap .icoWrap{left:34px;}
	.mvpWrap .rightDataWrap ul li:nth-child(2) .itemWrap .icoWrap{left:29px;top:-140px;}
	.mvpWrap .rightDataWrap ul li:nth-child(3) .itemWrap .icoWrap{left:24px;top:-140px;}
	.mvpWrap .rightDataWrap ul li:nth-child(4) .itemWrap .icoWrap{left:20px;top:-137px;}
	.mvpWrap .rightDataWrap ul li:nth-child(5) .itemWrap .icoWrap{left:14px;top:-137px;}
	.mvpWrap .rightDataWrap ul li:nth-child(6) .itemWrap .icoWrap{left:8px;top:-136px;}
	.mvpWrap .rightDataWrap ul li .itemWrap .icoWrap:after{width:110px;height:110px;top:-5px;}
	.mvpWrap .rightDataWrap ul li:nth-child(1) .itemWrap .icoWrap:after{left:-5px;}
	.mvpWrap .rightDataWrap ul li .itemWrap .dataWrap{width:126px;}
	.mvpWrap .rightDataWrap ul li:nth-child(1) .itemWrap .dataWrap{left:22px;}
	.mvpWrap .rightDataWrap ul li:nth-child(2) .itemWrap .dataWrap{left:16px;}
	.mvpWrap .rightDataWrap ul li:nth-child(3) .itemWrap .dataWrap{left:10px;}
	.mvpWrap .rightDataWrap ul li:nth-child(4) .itemWrap .dataWrap{left:8px;}
	.mvpWrap .rightDataWrap ul li:nth-child(5) .itemWrap .dataWrap{left:2px;}
	.mvpWrap .rightDataWrap ul li:nth-child(6) .itemWrap .dataWrap{left:-6px;}
	.mvpWrap .rightDataWrap ul li .itemWrap .dataWrap h2{font-size:1.2rem;letter-spacing:0;}
	.mvpWrap .rightDataWrap ul li .itemWrap .dataWrap:after{left:62px;}
	.aboutSectionWrap .dataWrap h2{margin:0 0 8px;}
	.vacanciesWrap .itemWrap p{min-height:208px;}
	.pricingContent.stepTwoData ul li .controlCheckbox .controlIndicator .dataWrap{min-height:96px;}
	.staffAugBenefitsWrap ul li .itemWrap .dataWrap{min-height:216px;}
	.servCaseStudyWrap.servCaseStudyWrapPy ul li{height:500px!important;}
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(1){left:310px;bottom:12px;}
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(2){right:152px;bottom:30px;}
	.commponTabWrap .nav-tabs:after{height:180px;top:-18px;background-size:contain;}
	.mobileSecWrap .videoWrap svg, 
	.crmWrap.commonCotentWrap .videoWrap svg, 
	.customAppDevWrap .videoWrap svg{width:320px;}
	.commponTabWrap .nav-tabs li:nth-child(1){left:362px;bottom:-4px;}
	.commponTabWrap .nav-tabs li:nth-child(2){right:170px;bottom:14px;}
	.howWeWrap .topService:before{left:54%;}
}
@media(max-width:1280px){

}
@media(max-width:1199px){
	.container{max-width:90%;}
	.mvpWrap{padding:0 0 30px;}
	.focusWrap{padding:0 0 10px;}
	.homeIntroWrap{padding:10px 0 60px;}
	.productsList .itemWrap .innerWrap{padding:35px 20px 15px;}
	.mvpWrap .rightDataWrap ul li:nth-child(1) .itemWrap .dataWrap{left:-70px;}
	.homeIntroWrap .itemWrap{padding:20px 18px;}
	.mvpWrap .rightDataWrap{max-width:940px;}
	.mvpWrap .rightDataWrap ul li:nth-child(1) .itemWrap .icoWrap{left:37px;top:-142px;}
	.mvpWrap .rightDataWrap ul li:nth-child(1) .itemWrap .dataWrap{left:22px;top:52px;}
	.mvpWrap .rightDataWrap ul li:nth-child(2) .itemWrap .icoWrap{left:31px;top:-142px;}
	.mvpWrap .rightDataWrap ul li:nth-child(2) .itemWrap .dataWrap{left:20px;top:8px;}
	.mvpWrap .rightDataWrap ul li:nth-child(3) .itemWrap .icoWrap{left:26px;top:-143px;}
	.mvpWrap .rightDataWrap ul li:nth-child(3) .itemWrap .dataWrap{left:14px;top:52px;}
	.mvpWrap .rightDataWrap ul li:nth-child(4) .itemWrap .icoWrap{left:22px;top:-140px;}
	.mvpWrap .rightDataWrap ul li:nth-child(4) .itemWrap .dataWrap{left:10px;top:8px;}
	.mvpWrap .rightDataWrap ul li:nth-child(5) .itemWrap .icoWrap{left:16px;top:-139px;}
	.mvpWrap .rightDataWrap ul li:nth-child(5) .itemWrap .dataWrap{left:4px;top:52px;}
	.mvpWrap .rightDataWrap ul li:nth-child(6) .itemWrap .icoWrap{left:10px;top:-138px;}
	.mvpWrap .rightDataWrap ul li:nth-child(6) .itemWrap .dataWrap{left:-4px;top:8px;}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap{width:85%;}
	.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu{left:88%;}
	.headerWrap .navbar-nav .megaMenu.megaMenu2.nav-item.dropdown .dropdown-menu{left:-26%;}
	.aboutSectionWrap .timelineWrap ul li:nth-child(1):after{left:130px;}
	.aboutSectionWrap .timelineWrap ul li:nth-child(2):after{right:130px;bottom:-74px;}
	.aboutSectionWrap .timelineWrap ul li:nth-child(3):after{left:130px;bottom:-86px;}
	.aboutSectionWrap .timelineWrap ul li{max-width:65%;}
	.staffAugItroWrap .dataWrap h2{font-size:2.4rem;line-height:36px;}
	.staffAugBenefitsWrap ul li .itemWrap .dataWrap{min-height:228px;}
	.staffAugBenefitsWrap ul li .itemWrap .dataWrap h2,
	.staffOnBoardWrap ul li .itemWrap .dataWrap h2{line-height:24px;}
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(1){left:290px;}
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(2){right:134px;bottom:20px;}
	.commponTabWrap .nav-tabs li:nth-child(1){left:336px;}
	.commponTabWrap .nav-tabs li:nth-child(2){right:154px;bottom:16px;}
}
@media(max-width:1148px){
	.navbar-nav .nav-link{margin-right:15px;}
	.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu{left:94%;}
	.headerWrap .navbar-nav .megaMenu.megaMenu2.nav-item.dropdown .dropdown-menu{left:-46%;}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap{width:90%;}
	.teamDataWrap .itemWrap .dataWrap{min-height:492px;}
}
@media(max-width:1120px){
	.mvpWrap .mvpBoxWrap{padding:30px 0 20px;}
	.container{max-width:100%;padding:0 30px;}
	.whyUsWrap .leftWrap:before{content:none;}
	.productsList .slick-arrow{bottom:-16px;top:auto;transform:none;}
	.productsList .slick-arrow.slick-prev{left:auto;right:50px;}
	.productsList .slick-arrow.slick-next{right:12px;}
	.serviceItemWrap{overflow:hidden;}
	.copyrightWrap .dataWrap p,
	.copyrightWrap ul li a,
	.copyrightWrap .locationWrap ul li,
	.copyrightWrap .locationWrap p{font-size:1.2rem;}
	.testimonialsWrap .itemWrap .dataWrap .topWrap{margin:0 0 25px;}
	.vacanciesWrap .itemWrap p{min-height:260px;}
	.vacanciesWrap .itemWrap h2{font-size:1.8rem;}
}
@media(max-width:1100px){
	.pricingContent.stepTwoData ul li .controlCheckbox .controlIndicator .dataWrap{min-height:112px;}
	.staffAugBenefitsWrap ul li .itemWrap .dataWrap{min-height:262px;}
	.servCaseStudyWrap ul li{height:360px;}
	.startupTabWrap.commponTabWrap .nav-tabs li .nav-link:after,
	.commponTabWrap .nav-tabs li .nav-link:after{width:80px;height:80px;}
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(1){left:288px;bottom:28px;}
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(2){right:118px;bottom:40px;}
	.testingWrapper .dataWrap{padding:0;}
	.commponTabWrap .nav-tabs li:nth-child(1){left:340px;}
	.commponTabWrap .nav-tabs li:nth-child(2){right:140px;}
	.innerPageIntroData ul.aiMlList{padding:0 0 0 30px;}
}
@media(max-width:1099px){
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(1){left:290px;bottom:2px;}
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(2){right:118px;bottom:16px;}
}
@media(max-width:1070px){
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(1){left:274px;bottom:6px;}
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(2){right:110px;}
	.commponTabWrap .nav-tabs li:nth-child(1){left:310px;}
	.commponTabWrap .nav-tabs li:nth-child(2){right:122px;bottom:18px;}
}
@media(max-width:1050px){
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(1){left:264px;bottom:2px;}
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(2){right:108px;}
}
@media(max-width:1024px){
	.homeIntroWrap .itemWrap .dataWrap p{min-height:102px;}
	.copyrightWrap ul li{margin:0 0 0 10px;}
	.copyrightWrap .locationWrap ul li{margin:0 6px 0 0!important;padding:0 6px 0 0;}
	.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu{left:140%;}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap h2{font-size:3rem;margin:0 0 10px;}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap p{font-size:1.6rem;}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap ul{padding:20px 0 0;}
	.staffAugBenefitsWrap ul{justify-content:center;}
	.staffAugBenefitsWrap ul li{width:33.33%;margin:0 0 24px;}
	.staffAugBenefitsWrap.javaBenefitsWrap ul li, 
	.staffAugBenefitsWrap.nodejsBenefitsWrap ul li{width:33.33%;margin:0 0 24px;}
	.staffAugBenefitsWrap ul li .itemWrap .dataWrap{min-height:140px;}
	.servCaseStudyWrap.servCaseStudyWrapPy ul li{height:auto!important;}
	.masonaryWrap .itemWrap{padding:20px;}
	.masonaryWrap .itemWrap .dataWrap h2{font-size:1.8rem;line-height:26px;}
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(1){left:248px;bottom:8px;}
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(2){right:94px;}
	.aimlBenefitsWrap .itemWrap{padding:24px;}
	.aimlBenefitsWrap .itemWrap .dataWrap h2{font-size:1.8rem;}
	.mobileSecWrap .videoWrap svg, 
	.crmWrap.commonCotentWrap .videoWrap svg, 
	.customAppDevWrap .videoWrap svg{width:280px;}
	.commponTabWrap .nav-tabs li:nth-child(1){left:308px;bottom:2px;}
	.commponTabWrap .nav-tabs li:nth-child(2){right:124px;}
	.testingValueWrap{margin:16px auto 0;}
}
@media(max-width:991px){
	.container{padding:0 20px;}
	.navbar-nav .nav-link{margin-right:6px;}
	.navbar-brand{width:72px;margin:0;}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap{width:100%;}
	.navbar-nav .nav-link{font-size:1.2rem;}
	.bannerWrap .itemWrap .innerWrap .dataWrap{width:100%;padding:0 30px;}
	.bannerWrap .itemWrap .innerWrap .dataWrap h2{font-size:4rem;}
	.bannerWrap .slick-arrow.slick-prev{left:20px;}
	.bannerWrap .slick-arrow.slick-next{right:20px;}
	.aboutSectionWrap.padBtm{padding:60px 0;}
	.aboutSectionWrap .imgWrap{text-align:center;margin:0 auto;}
	.aboutSectionWrap .timelineWrap ul li{max-width:88%;}
	.teamDataWrap .itemWrap{padding:0 20px 30px;}
	.teamDataWrap .itemWrap .dataWrap p{font-size:1.4rem;line-height:22px;}
	.teamDataWrap .itemWrap .dataWrap{min-height:520px;}
	.pricingPageWrap .titleWrap h2{font-size:2.2rem!important;line-height:32px;}
	.pricingContent ul li .controlCheckbox .dataWrap{min-height:40px;}
	.pricingContent.stepThreeData ul li .controlCheckbox .dataWrap{min-height:82px;}
	.staffOnBoardWrap ul li{padding:0 8px;}
	.staffOnBoardWrap:after{content:none;}
	.staffOnBoardWrap ul{justify-content:center;margin:0 auto;}
	.staffOnBoardWrap ul li{width:33.33%;}
	.staffOnBoardWrap ul li:nth-child(2), 
	.staffOnBoardWrap ul li:nth-child(4){margin:0 0 16px;}
	.staffAugItroWrap .imgWrap img{width:85%;}
	.staffAugItroWrap .imgWrap:before{width:340px;height:340px;}
	.staffAugItroWrap .imgWrap:after{width:340px;height:340px;right:28px;}
	.staffAugItroWrap .dataWrap.dataWrapPadRt,
	.staffAugItroWrap .dataWrap.dataWrapPadLt{padding:0;}
	.staffAugItroWrap .imgWrap{margin:70px auto 0;}
	.staffAugItroWrap{padding:60px 0 20px;}
	.staffAugBenefitsWrap{margin:40px auto 0;}
	.staffAugValueWrap .dataWrap{margin:0 auto;}
	.staffAugFaqWrap .rightWrap{padding:16px;}
	.staffAugFaqWrap .rightWrap h2,
	.aboutSectionWrap .dataWrap h2,
	.CommonBtmWrap .dataWrap h2{font-size:2.4rem;line-height:32px;}
	.staffAugValueWrap.reactValueWap{padding:60px 0;}
	.testingWrapper .container{padding:0;}
	.manuFacturListWrap .itemWrap .dataWrap h2{font-size:1.8rem;line-height:26px;}
	.manufacturSecWrap .dataWrap{padding:0;}
	.manufacturSecWrap{padding:20px 0 40px;}
	.fashionWrap.aboutSectionWrap{padding:40px 0;}
	.testPageTopWrap .itemWrap{padding:16px;}
	.innerPageIntroData ul.aiMlList{padding:0;}
}
@media(max-width:949px){
	.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul{padding:20px 16px 20px;}
	.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu{left:160%;max-width:700px!important;width:700px!important;}
	.headerWrap .navbar-nav .megaMenu.megaMenuOne.nav-item.dropdown .dropdown-menu, 
	.headerWrap .navbar-nav .megaMenu.megaMenu2.nav-item.dropdown .dropdown-menu{max-width:460px!important;width:460px!important;}
	.headerWrap .navbar-nav .megaMenu.megaMenu2.nav-item.dropdown .dropdown-menu{left:-66%;}
	.mvpWrap .rightDataWrap{max-width:760px;padding:0 12px 30px;min-height:410px;}
	.mvpWrap .rightDataWrap ul li .itemWrap .icoWrap{width:80px;height:80px;}
	.mvpWrap .rightDataWrap ul li .itemWrap .icoWrap:after{width:90px;height:90px;}
	.mvpWrap .rightDataWrap ul li:nth-child(1) .itemWrap .icoWrap{left:30px;top:-115px;}
	.mvpWrap .rightDataWrap ul li:nth-child(1) .itemWrap .dataWrap{left:6px;}
	.mvpWrap .rightDataWrap ul li:nth-child(2) .itemWrap .icoWrap{left:25px;top:-113px;}
	.mvpWrap .rightDataWrap ul li:nth-child(2) .itemWrap .dataWrap{left:4px;}
	.mvpWrap .rightDataWrap ul li:nth-child(3) .itemWrap .icoWrap{left:21px;top:-115px;}
	.mvpWrap .rightDataWrap ul li:nth-child(3) .itemWrap .dataWrap{left:2px;}
	.mvpWrap .rightDataWrap ul li:nth-child(4) .itemWrap .icoWrap{left:18px;top:-112px;}
	.mvpWrap .rightDataWrap ul li:nth-child(4) .itemWrap .dataWrap{left:-4px;}
	.mvpWrap .rightDataWrap ul li:nth-child(5) .itemWrap .icoWrap{left:12px;top:-112px;}
	.mvpWrap .rightDataWrap ul li:nth-child(5) .itemWrap .dataWrap{left:-8px;}
	.mvpWrap .rightDataWrap ul li:nth-child(6) .itemWrap .icoWrap{left:8px;top:-110px;}
	.mvpWrap .rightDataWrap ul li:nth-child(6) .itemWrap .dataWrap{left:-16px;}
	.mvpWrap .leftDataWrap h2{font-size:3.8rem;}
	.serviceItemWrap .titleWrap{text-align:center;margin:0 auto 20px;}
	.productWrap #slider{width:65%;height:300px;}
	.homeIntroWrap .col-md-4{padding:0 8px;}
	.titleWrap h2{font-size:3.6rem!important;}
	.homeIntroWrap .itemWrap .dataWrap h2{font-size:2.2rem;}
	.homeIntroWrap .itemWrap .dataWrap p{min-height:130px;}
	.testimonialsWrap .itemWrap .dataWrap{padding:20px 22px;}
	.recommendWrap .itemWrap .dataWrap{width:80%;}
	.recommendWrap .itemWrap .dataWrap h2{font-size:1.4rem;line-height:20px;}
	.recommendWrap .itemWrap .imgWrap{width:68px;height:68px;}
	.reachOutWrap .dataWrap{padding:30px 10px 32px;}
	.copyrightWrap{padding:6px 0;}
	.copyrightWrap .dataWrap p, 
	.copyrightWrap ul li a, 
	.copyrightWrap .locationWrap ul li, 
	.copyrightWrap .locationWrap p{font-size:1rem;font-weight:500;letter-spacing:0;}
	.copyrightWrap ul li{margin:0 0 0 8px;}
	.copyrightWrap .locationWrap ul li:nth-child(1){margin:0 6px 0 6px !important;}
	.copyrightWrap .pad0{padding:0;}
	.contactWrap .titleWrap h2,
	.manufacturSecWrap .dataWrap h2,
	.commonCotentWrap .titleWrap h2,
	.whyHumingtecSec .titleWrap h2,
	.aiMlInsdustWrap .dataWrap h2{font-size:2.4rem!important;}
	.servCaseStudyWrap .itemWrap .imgWrap{display:none;}
	.servCaseStudyWrap .itemWrap .dataWrap{width:100%;}
	.servCaseStudyWrap{padding:60px 0 0;}
	.servCaseStudyWrap.servCaseStudyWrapPy .itemWrap .dataWrap{width:100%;}
	.startupTabWrap .titleWrap h2,
	.CommonBtmWrap .dataWrap h2{font-size:2.2rem!important;}
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(1){left:222px;}
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(2){right:74px;}
	.commponTabWrap .nav-tabs li:nth-child(1){left:290px;}
	.commponTabWrap .nav-tabs li:nth-child(2){right:110px;}
}
@media(max-width:899px){
	.mobileSecWrap .videoWrap svg, 
	.crmWrap.commonCotentWrap .videoWrap svg, 
	.customAppDevWrap .videoWrap svg{width:230px;}
	.commponTabWrap .nav-tabs li:nth-child(1){left:274px;bottom:20px;}
	.commponTabWrap .nav-tabs li:nth-child(2){right:98px;bottom:40px;}
	.commponTabWrap .nav-tabs:after{top:-36px;}
}
@media(max-width:870px){
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(1){left:194px;}
	.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(2){right:54px;}
	.commponTabWrap .nav-tabs li:nth-child(1){left:250px;}
	.commponTabWrap .nav-tabs li:nth-child(2){right:86px;}
}
@media(max-width:810px){
	.commponTabWrap .nav-tabs li:nth-child(1){left:232px;}
}
@media(max-width:767px){
	html{font-size:60%;}
	header{position:relative;}
	.navbar{height:60px;}
	.navbar-toggler{padding:0;border:0!important;border-radius:0!important;margin:0!important;}
	.navbar-toggler:focus{outline:0;box-shadow:none;}
    .navbar-toggler-icon{background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='22' height='22' x='0' y='0' viewBox='0 0 30 30'><g><path d='M4 7h22M4 15h22M4 23h22' stroke='%23ffffff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' opacity='1' data-original='%23701A75'></path></g></svg>");background-repeat:no-repeat;}
    .offcanvas-header{background-color:#ffffff;}
    .offcanvas-body{background-color:#ffffff;padding:0;margin:20px 0 0;}
    .offcanvas-title{font-weight:700!important;font-size:2.6rem!important;display:none;}
	.navbar-nav{padding:0!important;}
    .navbar-nav .nav-link{font-size:1.3rem;padding:12px 15px;border-bottom:1px solid #f1f1f1;color:#000000;width:100%;text-align:left;margin:0;justify-content:space-between;height:auto;letter-spacing:0;font-weight:700;}
    .navbar-nav .dropdown-menu{background-color:#fafafa;box-shadow:none!important;border:0!important;padding:0;margin:0;border-radius:0;}
	.navbar-nav .megaMenuOne .dropdown-toggle.nav-link{padding:0 15px 0 0;}
	.navbar-nav .dropdown-menu ul li a,
	.navbar-nav .dropdown-menu .dropdown-item{padding:10px 20px;font-size:1.2rem;font-weight:500;width:100%;display:inline-block;border-bottom:1px solid #f1f1f1;}
	.navbar-nav .dropdown-menu ul li .mobDis{font-weight:700;color:#000000;font-size:1.3rem;}
	.titleWrap h2{font-size:3.2rem!important;}
	.recommendWrap .row{flex-flow:column;align-items:center;}
	.recommendWrap .col-md-4,
	.homeIntroWrap .col-md-4{max-width:520px;width:100%;}
	.recommendWrap .itemWrap{flex-flow:column-reverse;margin:0 auto 20px;}
	.recommendWrap .itemWrap .imgWrap{position:relative;right:auto;top:auto;transform:none;width:100px;height:100px;margin:0 auto;}
	.recommendWrap .itemWrap .dataWrap{text-align:center;width:100%;margin:15px auto 0;}
	.recommendWrap .itemWrap .dataWrap h2{margin:3px auto 10px;}
	.reachOutWrap .dataWrap h2{font-size:2rem;line-height:30px;}
 	.homeIntroWrap .itemWrap{margin:0 auto 20px;}
	.homeIntroWrap .itemWrap .dataWrap p{min-height:1px;}
	.mvpWrap .rightDataWrap{max-width:100%;min-height:1px;}
	.mvpWrap .rightDataWrap ul{max-width:100%;}
	.mvpWrap .rightDataWrap .imgWrap{display:none;}
	.mvpWrap .rightDataWrap ul{display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:space-between;padding:30px 0 0;}
	.mvpWrap .rightDataWrap ul li{width:48%;margin:0 0 30px;}
	.mvpWrap .rightDataWrap ul li .itemWrap{display:flex;flex-flow:column;align-items:center;justify-content:center;}
	.mvpWrap .rightDataWrap ul li .itemWrap .icoWrap{position:relative;left:auto!important;top:auto!important;transform:none;}
	.mvpWrap .rightDataWrap ul li:nth-child(1) .itemWrap .icoWrap:after{background-color:#85BD34;}
	.mvpWrap .rightDataWrap ul li:nth-child(2) .itemWrap .icoWrap:after{background-color:#68C07B;}
	.mvpWrap .rightDataWrap ul li:nth-child(3) .itemWrap .icoWrap:after{background-color:#269ABB;}
	.mvpWrap .rightDataWrap ul li:nth-child(4) .itemWrap .icoWrap:after{background-color:#3758D1;}
	.mvpWrap .rightDataWrap ul li:nth-child(5) .itemWrap .icoWrap:after{background-color:#3758D1;}
	.mvpWrap .rightDataWrap ul li:nth-child(6) .itemWrap .icoWrap:after{background-color:#7B277D;}
	.mvpWrap .rightDataWrap ul li .itemWrap .dataWrap:after{content:none;}
	.mvpWrap .rightDataWrap ul li .itemWrap .dataWrap{position:relative;left:auto!important;top:auto!important;transform:none;width:calc(100% - 0px);text-align:center;margin:16px auto 0;}
	.mvpWrap .rightDataWrap ul li .itemWrap .dataWrap h3{display:none;}
	.mvpWrap .rightDataWrap ul li .itemWrap .dataWrap h2{text-align:center;font-size:1.5rem;}
	.mvpWrap .rightDataWrap ul li .itemWrap .dataWrap p{text-align:center;font-size:1.4rem;font-weight:500;}
	.focusWrap ul.focusList{padding:0;}
	.focusWrap ul.focusList li .itemWrap .numWrap{width:30px;}
	.focusWrap ul.focusList>li .itemWrap .dataWrap{width:calc(100% - 40px);}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap h2{font-size:2.8rem;}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap p{font-size:1.5rem;}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap ul{padding:14px 0 0;}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap ul li{font-size:1.4rem;font-weight:500;width:100%;margin:0 0 10px;line-height:19px;}
	.whyUsWrap .rightWrap{padding:0;margin:20px 0 0;}
	.contactUsPopup.modal.show .modal-dialog{max-width:90%;margin:0 auto;}
	.bannerWrap .slick-arrow,
	.industriesWrap .slick-arrow{height:26px;width:26px;}
	.industriesWrap .titleWrap{left:0px;padding:0 20px;}
	.productWrap .productSlde .zoom-container .dataWrap h2{font-size:2.2rem;line-height:28px;}
	.productWrap .productSlde .zoom-container .dataWrap p{font-size:1.4rem;line-height:22px;font-weight:500;}
	.testimonialsWrap .itemWrap .icoWrap{display:none;}
	.testimonialsWrap .itemWrap .dataWrap{width:100%;}
	.innerBanner,
	.topPageBanner{padding:60px 0;}
	.aboutSectionWrap .timelineWrap ul li{max-width:63%;}
	.teamDataWrap .col-md-4{max-width:520px;margin:0 auto;}
	.teamDataWrap .itemWrap{margin:80px auto 0;}
	.teamDataWrap .itemWrap .dataWrap{min-height:1px;}
	.copyrightWrap .dataWrap p, 
	.copyrightWrap ul li a, 
	.copyrightWrap .locationWrap ul li, 
	.copyrightWrap .locationWrap p{text-align:center;}
	.copyrightWrap .locationWrap{justify-content:center;}
	.serviceItemWrap canvas{opacity:0.3;}
	.footerMenu{padding:0;}
	.aboutSectionWrap .timelineWrap ul{padding:0;}
	.aboutSectionWrap.padBtm,
	.teamDataWrap,
	.careersIntroWrap.aboutSectionWrap.padBtmLess,
	.pricingPageWrap,
	.servTechIntro{padding:40px 0;}
	.footerMenu h2{margin:0 0 12px;}
	.footerMenu ul li{margin:0 0 4px;}
	.innerBanner .titleWrap h2{margin:0 auto 8px;}
	.aboutSectionWrap .dataWrap h2{font-size:2.8rem;}
	.vacanciesWrap .itemWrap p{min-height:1px;}
	.vacanciesWrap,
	.jobDetails{padding:40px 0 160px;}
	.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item{padding:0 30px;}
	.pricingContent ul li{width:25%;}
	.pricingPageWrap .titleWrap{padding:0 0 40px;}
	.pricingContent.stepTwoData ul li .controlCheckbox .controlIndicator .dataWrap{min-height:100px;}
	.pricingContent.stepThreeData ul li .controlCheckbox .dataWrap{min-height:60px;}
	.contactWrap{padding:40px 0 60px;}
	ul.contactAdrress{padding:6px 0;}
	ul.socialConnect{padding:6px 0 30px;}
	.staffAugItroWrap .imgWrap{margin:0 auto 20px;max-width:380px;}
	.staffAugItroWrap .imgWrap:before{width:270px;height:270px;left:70%;top:40%;}
	.staffAugItroWrap .imgWrap:after{width:260px;height:260px;right:8px;top:-40px;}
	.staffAugItroWrap .dataWrap h2{margin:0 0 8px;}
	.staffAugItroWrap.flexRevMob .row{flex-flow:column-reverse;}
	.staffAugBenefitsWrap{margin:0 auto;}
	.staffAugBenefitsWrap ul li .itemWrap .dataWrap{min-height:180px;}
	.staffAugBenefitsWrap{padding:40px 0;}
	.staffOnBoardWrap{padding:40px 0;}
	.staffAugFaqWrap{padding:40px 0 80px;}
	.staffAugFaqWrap .accordion{padding:0;}
	.staffAugFaqWrap .ttlWrap{text-align:center;margin:0 auto 20px;}
	.staffAugFaqWrap .ht100p{height:auto;}
	.staffAugFaqWrap .rightWrap{margin:30px auto 0;}
	.servCaseStudyWrap ul li:nth-child(1){top:20px;}
	.servCaseStudyWrap ul li:nth-child(2){top:80px;}
	.commponTabWrap .nav-tabs:after{content:none;}
	.startupTabWrap.commponTabWrap{padding:30px 0;}
	.commponTabWrap .nav-tabs,
	.startupTabWrap.commponTabWrap .nav-tabs{margin:0 auto;height:auto;padding:100px 0 0;display:flex;align-items:center;justify-content:center;width:100%;}
	.commponTabWrap .nav-tabs li{position:relative!important;left:auto!important;right:auto!important;top:auto!important;bottom:auto!important;transform:none!important;width:49%;}
	.commponTabWrap .nav-tabs li .nav-link:after,
	.startupTabWrap.commponTabWrap .nav-tabs li .nav-link:after{left:50%!important;transform:translateX(-50%)!important;top:-64px !important;}
	.commponTabWrap .nav-tabs .nav-link{padding:20px 10px 0;width:100%;max-width:100%;}
	.startupTabWrap.commponTabWrap .nav-tabs .nav-link{width:100%;max-width:100%;}
	.aboutSectionWrap{padding:40px 0;}
	.aboutSectionWrap .dataWrap{padding:0;}
	.mobileAppMasonary{margin:30px auto 0;}
	.manuFacturListWrap .itemWrap:after{content:none;}
	.manuFacturListWrap .itemWrap.noContBefore:before{content:"";}
	.manuFacturListWrap{padding:40px 0;}
	.manufacturSecWrap .imgWrap{max-width:320px;text-align:center;margin:0 auto 20px;}
	.manufacturSecWrap .dataWrap h2,
	.manufacturSecWrap .dataWrap ul li{margin:0 0 10px;}
	.fashionWrap.aboutSectionWrap{padding:0 0 40px;}
	.fashionWrap.aboutSectionWrap .masonaryWrap{padding:30px 0 0;}
	.fashionBtmWrap{padding:0 0 20px;}
	.fashionBtmWrap .itemWrap{height:auto;margin:0 auto 20px;}
	.aiMlWrap.commonCotentWrap .masonaryWrap{padding:30px 0 0;}
	.aimlBenefitsWrap .itemWrap{padding:20px;}
	.whyHumingtecSec{padding:0 0 40px;}
	.aiMlInsdustWrap{padding:0 0 40px;}
	.commonCotentWrap .bredCrumbsWrap{padding:2px 4px;}
	.commonCotentWrap .bredCrumbsWrap li{font-size:1.2rem;}
	.testingValueWrap .itmeWrap.afterContent:after{width:100%;height:1px;bottom:0px;left:0;border-right:0;border-bottom:1px dashed rgba(255, 255, 255, 0.5);top:auto;}
	.crmWrap.commonCotentWrap .masonaryWrap.padLeft{padding:60px 0 0;}
	.mobileSecWrap .videoWrap svg, 
	.crmWrap.commonCotentWrap .videoWrap svg, 
	.customAppDevWrap .videoWrap svg{width:360px;}
	.testPageTopWrap .itemWrap{margin:0 auto 20px;}
	.testPageTopWrap .itemWrap .dataWrap p{min-height:1px;}
	.testPageTopWrap{padding:40px 0;}
	.digiTranformWrap.commonCotentWrap .masonaryWrap{padding:30px 0 0;}
	.commonBannerWrap .slick-prev{left:82%;}
	.commonBannerWrap .slick-next{right:60px;}
	.aipageDataWrap.innerPageIntroData{padding:40px 0;}
	.aipageDataWrap.innerPageIntroData .sticyDiv{position:relative;top:auto;}
	.innerPageIntroData ul.aiMlList{padding:20px 0 0;}
	.navbar-nav .dropdown-menu ul li.bdrBtm0 a{font-weight:700;color:#000000;font-size:1.3rem;}
	.navbar-nav .dropdown-menu ul li.bdrBtm0 a svg,
	.navbar-nav .dropdown-menu ul li .mobDis svg{display:none;}
}
@media(max-width:659px){
	.pricingContent.stepTwoData ul li .controlCheckbox .controlIndicator .dataWrap{min-height:116px;}
	.staffAugBenefitsWrap ul li,
	.staffOnBoardWrap ul li,
	.staffAugBenefitsWrap.javaBenefitsWrap ul li, 
	.staffAugBenefitsWrap.nodejsBenefitsWrap ul li{width:50%;}
	.staffAugBenefitsWrap ul li .itemWrap .dataWrap{min-height:140px;}
	.staffOnBoardWrap ul li .itemWrap .dataWrap{min-height:160px;}
	.crmWrap.commonCotentWrap .videoWrap svg, 
	.customAppDevWrap .videoWrap svg{width:360px;}
}
@media(max-width:639px){
	.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item{padding:0 20px;}
	.pricingContent.stepThreeData ul li,
	.pricingContent.stepTwoData ul li{width:33.33%;}
	.pricingContent.stepTwoData ul li .controlCheckbox .controlIndicator .dataWrap{min-height:100px;}
	.servCaseStudyWrap ul li,
	.servCaseStudyWrap.nodeJsCsStudy ul li{height:auto!important;}
	.staffAugFaqWrap .accordion .accordion-item .accordion-collapse .accordion-body{padding:15px 0 0;}
	.mobileSecWrap .videoWrap svg, 
	.crmWrap.commonCotentWrap .videoWrap svg, 
	.customAppDevWrap .videoWrap svg{width:280px;}
	.commonBannerWrap .slick-prev{left:79%;}
}
@media(max-width:575px){
	.serviceItemWrap .main{width:300px;height:300px;}
	.serviceItemWrap .main .centerLogo svg{width:60px;height:60px;}
	li.iconBlock{width:44px;height:44px;}
	li.iconBlock svg{width:22px;height:22px;}
	ul.bigCircle li.iconBlock:nth-child(1){top:-24px;left:50%;transform:translateX(-50%);}
	ul.bigCircle li.iconBlock:nth-child(2){top:30px;right:10px;}
	ul.bigCircle li.iconBlock:nth-child(3){top:50%;right:-24px;transform:translateY(-50%);}
	ul.bigCircle li.iconBlock:nth-child(4){bottom:24px;right:24px;}
	ul.bigCircle li.iconBlock:nth-child(5){bottom:-20px;left:50%;transform:translateX(-50%);}
	ul.bigCircle li.iconBlock:nth-child(6){bottom:24px;left:24px;}
	ul.bigCircle li.iconBlock:nth-child(7){top:50%;left:-24px;transform:translateY(-50%);}
	ul.bigCircle li.iconBlock:nth-child(8){top:20px;left:24px;}
	ul.circle li.iconBlock:nth-child(1){top:-10px;left:50px;}
	ul.circle li.iconBlock:nth-child(2){top:-10px;right:36px;}
	ul.circle li.iconBlock:nth-child(3){top:48px;right:-14px;}
	ul.circle li.iconBlock:nth-child(4){bottom:44px;right:-10px;}
	ul.circle li.iconBlock:nth-child(5){bottom:-10px;right:40px;}
	ul.circle li.iconBlock:nth-child(6){bottom:-10px;left:46px;}
	ul.circle li.iconBlock:nth-child(7){bottom:44px;left:-14px;}
	ul.circle li.iconBlock:nth-child(8){top:40px;left:-10px;}
	ul.bgcloudList li svg{width:100px;height:100px;}
	.bannerWrap .slick-prev,
	.bannerWrap .slick-next{top:60%;}
	.industriesWrap .titleWrap p{width:100%;max-width:100%;}
	.industriesWrap .slick-prev{left:20px;}
	.industriesWrap .slick-next{right:20px;}
	.innerBanner,
	.topPageBanner{padding:40px 0;}
	.aboutSectionWrap .timelineWrap ul li{max-width:88%;}
	.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item .nav-link{width:40px;height:40px;}
	.pricingContent h3{font-size:2rem;margin:40px auto 0;}
	.pricingPageWrap .pricingWrap ul.nav-tabs{width:100%;margin:0;}
	.ourClients ul li{width:25%;margin:0;}
	.controlIndicator{padding:10px;}
	.pricingContent ul li{width:33.33%;}
	.pricingContent.stepThreeData ul li,
	.pricingContent.stepTwoData ul li{width:50%;}
	.pricingContent.stepTwoData ul li .controlCheckbox .controlIndicator .dataWrap{min-height:72px;}
	.pricingContent.stepsCommonData ul li .itemWrap{flex-flow:column;}
	.pricingContent.stepsCommonData ul li .itemWrap h3{width:100%;text-align:center;margin:0 auto 16px;}
	.staffAugBenefitsWrap ul li .itemWrap .dataWrap{min-height:210px;}
	.staffOnBoardWrap ul li .itemWrap .dataWrap{min-height:190px;}
	.servCaseStudyWrap .itemWrap{padding:20px;}
	.servCaseStudyWrap .itemWrap p{line-height:26px;}
	.startupTabWrap.commponTabWrap .nav-tabs{flex-flow:row wrap;}
	.startupTabWrap.commponTabWrap .nav-tabs{padding:70px 0 0;}
	.startupTabWrap.commponTabWrap .nav-tabs li .nav-link:after{top:-50px!important;width:60px;height:60px;}
	.commponTabWrap .nav-tabs .nav-link{font-size:1.6rem;line-height:20px;}
	.commonBannerWrap .slick-prev{left:75%;}
}
@media(max-width:480px){
	.focusWrap ul.focusList>li{padding:20px;}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap p{font-size:1.4rem;line-height:20px;}
	.productWrap #slider{height:240px;width:68%;}
	.productWrap .productSlde .zoom-container{flex-flow:column;justify-content:center;align-items:center;}
	.productWrap .productSlde.productSlde1 .zoom-container .dataWrap{padding:20px;width:100%;}
	.productWrap .productSlde .zoom-container .imgWrap{display:none;}
	.productWrap .productSlde .zoom-container .dataWrap p{margin:0 auto 12px;}
	.productWrap .productSlde .zoom-container .dataWrap{width:100%;padding:20px;text-align:center;}
	.bannerWrap .itemWrap .innerWrap .dataWrap h2{font-size:3.6rem;}
	.titleWrap h2{font-size:2.4rem!important;}
	.contactWrap .formWrap{padding:40px 20px;}
	.contactWrap ul.formList li.half{width:100%;}
	.commonBannerWrap .slick-prev{left:73%;}
}
@media(max-width:479px){
	.staffAugBenefitsWrap ul li, 
	.staffOnBoardWrap ul li,
	.staffAugBenefitsWrap.javaBenefitsWrap ul li, 
	.staffAugBenefitsWrap.nodejsBenefitsWrap ul li{width:100%;margin:0 auto 20px;}
	.staffAugBenefitsWrap ul li:last-child, 
	.staffOnBoardWrap ul li:last-child{margin:0 auto;}
	.staffOnBoardWrap ul li .itemWrap .dataWrap,
	.staffAugBenefitsWrap ul li .itemWrap .dataWrap{min-height:1px;}
	.staffAugItroWrap .imgWrap:before{left:50%;top:50%;}
	.staffAugItroWrap .imgWrap:after{right:36px;}
	.staffAugItroWrap .dataWrap h2{font-size:2.2rem;line-height:32px;}
	.commonBannerWrap .slick-prev{left:70%;}
}
@media(max-width:439px){
	.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item{padding:0 14px;}
	.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item:nth-child(1){padding-left:0;}
	.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item:nth-child(5){padding-right:0;}
	.commonBannerWrap .slick-prev{left:68%;}
	.innerPageIntroData ul.aiMlList li .itemWrap{padding:20px 4px;}
	.innerPageIntroData ul.aiMlList li .itemWrap .imgWrap{height:80px;width:80px;}
	.innerPageIntroData ul.aiMlList li .itemWrap .dataWrap{margin:10px auto 0;}
	.innerPageIntroData ul.aiMlList li .itemWrap .dataWrap h2{font-size:1.4rem;margin:20px auto 0;line-height:20px;}
}
@media(max-width:380px){
	.mvpWrap .leftDataWrap h2{font-size:3.2rem;}
	.productWrap .productSlde .zoom-container .dataWrap a{font-size:1.2rem;font-weight:500;padding:4px 10px;}
	.productWrap #slider{height:300px;}
	.contactUsPopup .modal-body ul.formList li{width:100%;}
	.industriesWrap .titleWrap p{font-size:1.4rem;line-height:24px;font-weight:500;}
	.pricingContent ul li{width:50%;}
	.crmWrap.commonCotentWrap .videoWrap svg, .customAppDevWrap .videoWrap svg{width:300px;}
	.commonBannerWrap .slick-prev{left:62%;}
}
@media(max-width:379px){
	.pricingContent.stepTwoData ul li .controlCheckbox .controlIndicator .dataWrap{min-height:100px;}
}
@media(max-width:359px){
	.mvpWrap .rightDataWrap ul li{width:100%;}
	.mvpWrap .mvpBoxWrap{padding:20px;}
	.mvpWrap .leftDataWrap h2{font-size:2.8rem;}
	.mvpWrap .mvpBoxWrap .ctaWrap .btn{font-size:1.1rem;padding:8px 14px;}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap span{font-size:1.6rem;}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap h2{font-size:1.8rem;}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap p{font-size:1.3rem;}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap ul li{font-size:1.3rem;padding:0 0 0 12px;}
	.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap ul li:before{width:8px;height:8px;top:6px;}
	.productWrap #slider{width:70%;}
	.ourPartnerWrap ul li{width:20%;}
	.serviceItemWrap .main{width:280px;height:280px;}
	li.iconBlock{width:38px;height:38px;}
	ul.bigCircle li.iconBlock:nth-child(3){right:-14px;}
	ul.bigCircle li.iconBlock:nth-child(7){left:-14px;}
	.serviceItemWrap .titleWrap h2{margin:0 0 10px;}
	.serviceItemWrap .titleWrap p{font-size:1.5rem;line-height:28px;}
	.aboutSectionWrap .timelineWrap ul li{max-width:94%;}
	.aboutSectionWrap .timelineWrap ul li:nth-child(1):after{left:90px;}
	.aboutSectionWrap .timelineWrap ul li:nth-child(2):after{right:90px;}
	.aboutSectionWrap .timelineWrap ul li:nth-child(3):after{left:90px;}
	.jobDetails .ctaWrap a{padding:6px 26px;margin:0 10px 0 0;}
	.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item{padding:0 12px;}
	.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item .nav-link{width:36px;height:36px;}
	.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item:nth-child(1):before{width:calc(100% - 30px);}
	.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item:nth-child(5):before{width:calc(100% - 30px);}
	.crmWrap.commonCotentWrap .videoWrap svg, 
	.customAppDevWrap .videoWrap svg{width:280px;}
	.mobileSecWrap .videoWrap svg, 
	.crmWrap.commonCotentWrap .videoWrap svg,
	.customAppDevWrap .videoWrap svg{width:240px;}
}