@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');
*{margin:0;padding:0;outline:0;}
html{font-size:63.5%;height:100%;}
body{font-family:'Plus Jakarta Sans', sans-serif;font-weight:400;font-style:normal;box-sizing:border-box;position:relative;font-size:1.4rem;line-height:26px;color:#000000;}
a, a:hover, a:focus, a:active{text-decoration:none;outline:0;color:#444343;}
input, input:focus, input:hover, input:active{outline:0;box-shadow:none;}
ul{margin:0 auto;padding:0;list-style:none;}
button, button:hover, button:active, button:focus, .btn.focus, .btn:focus{box-shadow:none;outline:0;border:0;}
.posRel{position:relative;}
/*---------NavBar Icon---------*/
#nav-icon{position:relative;width:28px;height:24px;cursor:pointer;z-index:98;}
#nav-icon span{display:block;position:absolute;height:2px;width:50%;background-color:#246789;opacity:1;-webkit-transform:rotate(0);-moz-transform:rotate(0);-o-transform:rotate(0);transform:rotate(0);-webkit-transition:all .35s ease-in-out;-moz-transition: all .3s ease-in-out;-o-transition: all .3s ease-in-out;transition: all .3s ease-in-out;}
.navbar.sticky-top.nav-bg #nav-icon span{background-color:#ef3f36;}
#nav-icon span:nth-child(even){left:50%;}
#nav-icon span:nth-child(odd){left:0;}
#nav-icon span:nth-child(1), #nav-icon span:nth-child(2){top:0;}
#nav-icon span:nth-child(3), #nav-icon span:nth-child(4){top:5px;}
#nav-icon span:nth-child(5), #nav-icon span:nth-child(6){top:10px;}
#nav-icon span:nth-child(7), #nav-icon span:nth-child(8){top:15px;}
#nav-icon.open span:nth-child(1), #nav-icon.open span:nth-child(8){-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg);-o-transform:rotate(45deg);transform:rotate(45deg);}
#nav-icon.open span:nth-child(2), #nav-icon.open span:nth-child(7){-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg);-o-transform:rotate(-45deg);transform:rotate(-45deg);}
#nav-icon.open span:nth-child(1){left:1px;top:2px;}
#nav-icon.open span:nth-child(2){left:calc(50% - 6px);top:2px;}
#nav-icon.open span:nth-child(3){left:-50%;opacity:0;}
#nav-icon.open span:nth-child(4){left:100%;opacity:0;}
#nav-icon.open span:nth-child(5){left:-50%;opacity:0;}
#nav-icon.open span:nth-child(6){left:100%;opacity:0;}
#nav-icon.open span:nth-child(7){left:1px;top:9px;}
#nav-icon.open span:nth-child(8){left:calc(50% - 6px);top:9px;}
/*---------NavBar---------*/
header{position:fixed;left:0;width:100%;top:0;z-index:9999;}
.navbar{position:relative;width:100%;z-index:9999;-webkit-transition:all linear .3s;-o-transition:all linear .3s;transition:all linear .3s;display:flex;align-items:center;justify-content:space-between;padding:0;height:80px;box-shadow:none;background:#701A75;}
.homePageHeader .navbar{background:#701A75;}
.navbar.homeNav{background:#701A75;}
.navbar-collapse{justify-content:space-between;}
.navbar-collapse .rightCta{text-align:right;}
.navbar-collapse .rightCta a{color:#000000;font-size:1.3rem;font-weight:500;padding:6px 20px;border-radius:50px;background-color:#ffffff;letter-spacing:0.2px;display:inline-block;}
.navbar-nav{margin:0 auto;}
.navbar-nav .nav-link{color:#ffffff;font-size:1.4rem;font-weight:500;-webkit-transition:all linear .3s;-o-transition:all linear .3s;transition:all linear .3s;position:relative;margin-right:30px;text-transform:capitalize;letter-spacing:0.6px;height:80px;display:flex;align-items:center;justify-content:center;}
.homeNav .navbar-nav .nav-link{color:rgba(255,255,255,1);}
.navbar-nav li:last-child .nav-link{margin:0;}
.navbar-nav .nav-link:focus,
.navbar-nav .nav-link:hover,
.navbar-nav .nav-link.show{color:#fbbf24!important;}
.navbar-nav .nav-link.active{color:#fbbf24!important;}
.navbar-brand{width:100px;position:relative;display:inline-block;}
.navbar-brand img{width:100%;}
.navbarLogo{font-size:1.8rem;color:#ffffff;letter-spacing:1.2px;font-weight:600;text-transform:uppercase;display:inline-block;padding:0;}
.navbarLogo:hover,
.navbar-nav .nav-link.active, 
.navbar-nav .nav-link.show{color:#ffffff;}
.headerWrap .navbar-nav .nav-item.dropdown .dropdown-menu{-webkit-transition:all .3s linear 0s;-moz-transition:all .3s linear 0s;-ms-transition:all .3s linear 0s;-o-transition:all .3s linear 0s;transition:all .3s linear 0s;visibility:hidden;opacity:0;top:100px;}
.headerWrap .navbar-nav>.nav-item.dropdown:hover>.dropdown-menu{visibility:visible;opacity:1;top:80px;}
.headerWrap .navbar-nav .dropdown-menu{border-radius:0;box-shadow:0 10px 20px rgba(0, 0, 0, .08);background-color:#ffffff;min-width:240px;border:0;border-bottom:6px solid #fbbf24;padding:0;left:50%;transform:translateX(-50%);}
.headerWrap .navbar-nav .dropdown-menu .dropdown-item{color:#757575;font-size:1.3rem;padding:10px 12px;border-bottom:1px solid #f5f5f5;font-weight:500;}
.headerWrap .navbar-nav .dropdown .dropdown-toggle a.nav-link{display:inline-block;padding:0;margin:0;letter-spacing:0;} 
.headerWrap .navbar-nav .dropdown-menu .dropdown-item:hover,
.headerWrap .navbar-nav .dropdown-menu .dropdown-item:focus,
.headerWrap .navbar-nav .dropdown-menu .dropdown-item.active, 
.headerWrap .navbar-nav .dropdown-menu .dropdown-item:active{color:#701A75;background-color:transparent!important;}

.headerWrap .navbar-nav .megaMenu .dropdown-menu .dropdown-item:hover,
.headerWrap .navbar-nav .megaMenu .dropdown-menu .dropdown-item:focus,
.headerWrap .navbar-nav .megaMenu .dropdown-menu .dropdown-item.active, 
.headerWrap .navbar-nav .megaMenu .dropdown-menu .dropdown-item:active{color:#701A75;background-color:transparent!important;}

.headerWrap .navbar-nav .megaMenu .dropdown-menu .dropdown-item{padding:0;border:0;}
.headerWrap .navbar-nav .megaMenu .dropdown-menu .dropdown-item span{width:280px;border-bottom:1px solid #f5f5f5;padding:10px 12px;display:inline-block;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu{max-width:950px!important;width:950px!important;}
.headerWrap .navbar-nav .megaMenu.megaMenuOne.nav-item.dropdown .dropdown-menu,
.headerWrap .navbar-nav .megaMenu.megaMenu2.nav-item.dropdown .dropdown-menu{max-width:560px!important;width:560px!important;}
.headerWrap .navbar-nav .megaMenu.megaMenu2.nav-item.dropdown .dropdown-menu{left:10%;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;padding:20px 20px 30px;position:relative;overflow:hidden;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul li{position:relative;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities li{width:33.33%;}
.headerWrap .navbar-nav .megaMenu.megaMenuOne.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities>li,
.headerWrap .navbar-nav .megaMenu.megaMenu2.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities>li{width:50%;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities li a,
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities li ul.boldMenu li a,
.headerWrap .navbar-nav .megaMenu.megaMenuOne.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities>li ul.boldMenu>li a{color:#1b1f22;font-size:1.5rem;font-weight:700;padding:0 0 20px;display:inline-block;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities li ul{padding:0;margin:0;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities li ul li{width:100%;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities li ul.boldMenu li ul{margin:0 0 16px;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities li ul li a,
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities li ul.boldMenu li ul li a,
.headerWrap .navbar-nav .megaMenu.megaMenuOne.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities>li ul.boldMenu li ul li a{font-size:1.3rem;font-weight:600;padding:8px 0;display:inline-block;line-height:22px;color:#757575;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities li a:hover, 
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities li ul.boldMenu li a:hover{color:#701A75;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities>li a{position:relative;padding:0 0 12px;}
.headerWrap .navbar-nav .megaMenuOne .dropdown-toggle.nav-link .nav-link{height:auto;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities>li a svg{width:16px;height:16px;margin:0 5px 0 0;} 
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities li a:hover svg path{fill:#701A75;}

.headerWrap .navbar-nav .megaMenu.nav-item.dropdown.bigMegaMenu .dropdown-menu ul.megaMenuCapabilities li{width:100%;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown.bigMegaMenu .dropdown-menu ul.megaMenuCapabilities li ul{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown.bigMegaMenu .dropdown-menu ul.megaMenuCapabilities li ul li{width:33.33%;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown.bigMegaMenu .dropdown-menu ul.megaMenuCapabilities>li a{position:relative;padding:0 0 10px;color:#000000;text-transform:uppercase;font-size:1.4rem;letter-spacing:0.2px;font-weight:600;border-bottom:1px solid #f0f0f0;
display:block;width:100%;margin:16px 0 4px;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown.bigMegaMenu .dropdown-menu ul.megaMenuCapabilities li ul li a{font-size:1.3rem;font-weight:500;padding:6px 0;display:inline-block;line-height:22px;color:#4e4e4e;border:0;text-transform:capitalize;margin:0;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown.bigMegaMenu .dropdown-menu ul.megaMenuCapabilities li ul.padTopBig{padding:20px 0 0}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown.bigMegaMenu .dropdown-menu ul.megaMenuCapabilities li ul.padTopBig li a{color:#000000;text-transform:uppercase;font-size:1.4rem;letter-spacing:0.2px;font-weight:600;}
.martTop0px{margin:0 0 4px!important;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown.bigMegaMenu .dropdown-menu ul.megaMenuCapabilities li ul.padTopBig li a:hover,
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown.bigMegaMenu .dropdown-menu ul.megaMenuCapabilities>li a:hover,
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown.bigMegaMenu .dropdown-menu ul.megaMenuCapabilities li ul li a:hover{color:#701A75;position:relative;display:inline-block;width:auto;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown.bigMegaMenu .dropdown-menu ul.megaMenuCapabilities li ul li a:hover:after{content:"";width:100%;height:1px;background-color:#701A75;position:absolute;bottom:5px;left:0;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown.bigMegaMenu .dropdown-menu ul.megaMenuCapabilities li ul.padTopBig li a:hover:after{content:none;}
.bdrBtm0 a{border-bottom:0!important;}
.headerWrap .navbar-nav .megaMenu.nav-item.dropdown.bigMegaMenu .dropdown-menu ul.megaMenuCapabilities>li a:hover,
.headerWrap .navbar-nav .megaMenu.megaMenuSm.nav-item.dropdown .dropdown-menu ul.megaMenuCapabilities li a:hover{width:100%;}
.headerWrap .navbar-nav .megaMenu.megaMenuSm.nav-item.dropdown .dropdown-menu{width:620px!important;max-width:620px!important;left:-20%;}
/*---------HomePage Banner---------*/
.bannerWrap{width:100%;margin:0 auto;background-color:#ffffff;position:relative;padding:90px 0 0;position:relative;z-index:9;margin:-90px auto 0;box-shadow:0px 4px 8px 4px rgb(112 26 117 / 19%);}
.bannerWrap .pad0{padding:0;}
.bannerWrap .videoWrap{position:relative;z-index:99;border-radius:0px;overflow:hidden;height:590px;width:100%;}
.bannerWrap .videoWrap:before{content:"";width:100%;height:100%;position:absolute;bottom:0px;background-position:center center;left:50%;transform:translateX(-50%);z-index:1;background-color:#701a752b;}
.bannerWrap .videoWrap video{width:100%;border-radius:0px;height:100%;position:absolute;object-fit:cover;object-position:bottom;}
.bannerWrap .videoWrap.video3 video{object-position:center;}
.bannerWrap .slick-slide{margin:0 auto -8px;}
.bannerWrap .slick-arrow{position:absolute;bottom:-62px;background-color:rgba(255,255,255,0.7)!important;color:transparent;width:40px;height:40px;border-radius:100%;display:flex!important;align-items:center;justify-content:center;flex-flow:row wrap;z-index:99;}
.bannerWrap .slick-arrow:hover{background-color:rgba(255,255,255,1)!important;}
.bannerWrap .slick-prev{left:60px;}
.bannerWrap .slick-next{right:96px;}
.bannerWrap .slick-arrow:before{content:"";background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='512' height='512' x='0' y='0' viewBox='0 0 492 492' style='enable-background:new 0 0 512 512' xml:space='preserve'><g><path d='M198.608 246.104 382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z' fill='%23701A75' opacity='1' data-original='%23701A75'></path></g></svg>");width:14px;height:14px;position:absolute;background-size:cover;}
.bannerWrap .slick-arrow.slick-prev{left:140px;}
.bannerWrap .slick-arrow.slick-next{right:140px;}
.bannerWrap .slick-arrow.slick-next:before{transform:rotate(-180deg);}
.bannerWrap .itemWrap .innerWrap .dataWrap{position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);z-index:99;margin:0 auto;text-align:center;}
.bannerWrap .itemWrap .innerWrap .dataWrap h2{color:#ffffff;font-size:4.6rem;font-weight:900;position:relative;z-index:9;}
.bannerWrap .itemWrap .innerWrap .dataWrap p{color:#ffffff;font-size:1.6rem;font-weight:400;letter-spacing:0.2px;line-height:30px;margin:0px auto 20px;}
.bannerWrap .itemWrap .innerWrap .dataWrap a{color:#701A75;font-size:1.3rem;font-weight:500;padding:6px 20px;border-radius:50px;background-color:#ffffff;letter-spacing:0.2px;display:inline-block;}
.bannerWrap .itemWrap .innerWrap .dataWrap a:hover{background-color:#701A75;color:#ffffff;}

/*---------HomePage Intra Section---------*/
.homeIntroWrap{width:100%;margin:0 auto;padding:10px 0 100px;background-color:#ffffff;}
.homeIntroWrap .col-md-4{padding:0 15px;}
.homeIntroWrap .itemWrap{width:100%;position:relative;border-radius:10px;background-color:#fafafa;padding:20px 30px;}
.homeIntroWrap .itemWrap .iconWrap{position:relative;text-align:center;margin:0 auto 20px;}
.homeIntroWrap .itemWrap .iconWrap .imgWrap{position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);}
.homeIntroWrap .itemWrap .iconWrap img{position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);}
.homeIntroWrap .itemWrap .dataWrap h2{color:#000000;margin:0;font-size:2.8rem;font-weight:800;text-align:center;}
.homeIntroWrap .itemWrap .dataWrap p{color:#0F172A;font-size:1.4rem;font-weight:500;line-height:26px;letter-spacing:0.2px;margin:10px 0 30px;text-align:center;}
.homeIntroWrap .itemWrap .dataWrap a{color:#701A75;border-radius:30px;padding:0;font-size:1.3rem;font-weight:600;text-transform:capitalize;letter-spacing:0.4px;line-height:14px;display:block;text-align:right;}
.homeIntroWrap .itemWrap .dataWrap a svg{margin:0 0 0 4px;}
/*---------HomePage Focus---------*/
.focusWrap{width:100%;margin:0 auto;padding:20px 0 60px;}
.focusWrap .titleWrap{text-align:center;}
.focusWrap .titleWrap h2{color:#000000;font-weight:900;font-size:3.8rem;line-height:48px;text-transform:capitalize;margin:0 auto 16px;}
.focusWrap .titleWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;margin:0;letter-spacing:0.2px;font-weight:400;}
.focusWrap ul.focusList{padding:20px 0 0;}
.focusWrap ul.focusList>li{position:sticky;position:-webkit-sticky;height:336px;display:flex;align-items:flex-start;justify-content:center;margin:0 auto 50px;border-radius:30px;padding:30px;overflow:hidden;background-position:center;background-repeat:no-repeat;background-size:cover;}
.focusWrap ul.focusList>li:nth-child(1){background-image:url(../img/Digitization.jpg);top:90px;}
.focusWrap ul.focusList>li:nth-child(2){background-image:url(../img/Augmentation.jpg);top:120px;}
.focusWrap ul.focusList>li:nth-child(3){background-image:url(../img/Efficiency.jpg);top:154px;}
.focusWrap ul.focusList>li:nth-child(4){background-image:url(../img/Innovation.jpg);top:190px;}
.focusWrap ul.focusList>li:nth-child(5){background-image:url(../img/Mordernization.jpg);top:196px;}
.focusWrap ul.focusList>li .itemWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;width:100%;}
.focusWrap ul.focusList li .itemWrap .numWrap{width:60px;} 
.focusWrap ul.focusList li .itemWrap .numWrap p{margin:0;position:relative;font-size:1.6rem;font-weight:400;color:#ffffff;} 
.focusWrap ul.focusList li .itemWrap .numWrap p:nth-of-type(1){color:#ffffff;font-weight:600;padding:0 0 5px;margin:0 0 5px;}
.focusWrap ul.focusList li .itemWrap .numWrap p:nth-of-type(1):after{content:"";width:20px;height:2px;background-color:#ffffff;position:absolute;left:0;bottom:0;}
.focusWrap ul.focusList>li .itemWrap .dataWrap{width:calc(100% - 70px);display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;}
.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap{width:70%;}
.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap span{font-size:1.8rem;font-weight:700;letter-spacing:0.2px;margin:0 0 2px;display:inline-block;color:#ffffff;}
.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap h2{font-size:3.4rem;font-weight:800;margin:0 0 14px;color:#ffffff;letter-spacing:0.2px;}
.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap p{color:#ffffff;font-size:1.8rem;line-height:30px;margin:0;letter-spacing:0.2px;font-weight:400;}
.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap ul{padding:30px 0 0;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;}
.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap ul li{width:48%;font-size:1.6rem;font-weight:600;margin:0 0 18px;position:relative;color:#ffffff;padding:0 0 0 18px;}
.focusWrap ul.focusList li .itemWrap .dataWrap .leftWrap ul li:before{content:"";background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='512' height='512' x='0' y='0' viewBox='0 0 492 492' style='enable-background:new 0 0 512 512' xml:space='preserve'><g><path d='M198.608 246.104 382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z' fill='%23ffffff' opacity='1' data-original='%23ffffff'></path></g></svg>");transform:rotate(-180deg);width:12px;height:12px;background-size:cover;background-repeat:no-repeat;position:absolute;left:0px;top:8px;}
.focusWrap ul.focusList li .itemWrap .dataWrap .rightWrap{width:30%;position:relative;margin:0!important;}
.focusWrap ul.focusList li:nth-child(1) .itemWrap .dataWrap .rightWrap,
.focusWrap ul.focusList li:nth-child(2) .itemWrap .dataWrap .rightWrap,
.focusWrap ul.focusList li:nth-child(3) .itemWrap .dataWrap .rightWrap,
.focusWrap ul.focusList li:nth-child(5) .itemWrap .dataWrap .rightWrap{margin:0 auto -36px;}
.focusWrap ul.focusList li:nth-child(4) .itemWrap .dataWrap .rightWrap{margin:0 auto -9px;}
.focusWrap ul.focusList li .itemWrap .dataWrap .rightWrap .imgWrap{text-align:center;margin:0 auto;}
.focusWrap ul.focusList li .itemWrap .dataWrap .rightWrap .imgWrap img{object-fit:contain;mix-blend-mode:color-burn;}
.focusWrap ul.focusList li:nth-child(3) .itemWrap .dataWrap .rightWrap .imgWrap img{opacity:0.9;}
.focusWrap ul.focusList li .itemWrap .dataWrap .rightWrap .imgWrap{position:absolute;top:50%;right:-30px;z-index:9;transform:translateY(-50%);}
.focusWrap ul.focusList li .itemWrap .dataWrap .rightWrap .imgWrap video{width:100%;height:100%;}
/*---------HomePage Services---------*/
.serviceItemWrap{width:100%;margin:0 auto;padding:60px 0 90px;background-color:#701A75;position:relative;z-index:1;}
.serviceItemWrap canvas{position:absolute;top:0;left:0;height:100%;width:100%;}
.serviceItemWrap .titleWrap{text-align:left;margin:0;position:relative;z-index:1;}
.serviceItemWrap .titleWrap h2{color:#ffffff;font-weight:800;font-size:3.8rem;line-height:48px;text-transform:capitalize;margin:0 0 28px;}
.serviceItemWrap .titleWrap p{color:#ffffff;font-size:1.7rem;line-height:32px;margin:0;letter-spacing:0.2px;font-weight:400;}
.serviceItemWrap .itemWrap{padding:0 10px;}
.serviceItemWrap .itemWrap .innerData{box-shadow:0px 0px 10px 0px #00000012;border:1px solid #E2E8F0;background-color:#ffffff;border-radius:10px;padding:36px 20px;min-height:320px;position:relative;}
.serviceItemWrap .itemWrap .innerData .iconWrap{margin:0 auto 26px;}
.serviceItemWrap .itemWrap .innerData .dataWrap h2{text-transform:capitalize;font-size:1.8rem;font-weight:700;line-height:28px;margin:0 0 6px;}
.serviceItemWrap .itemWrap .innerData .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:26px;margin:0;letter-spacing:0.2px;font-weight:400;}
.serviceItemWrap .itemWrap .innerData .dataWrap .readMore{background-color:#f4f4f4;width:34px;height:34px;border-radius:100%;display:flex;align-items:center;justify-content:center;position:absolute;right:16px;bottom:16px;}
.serviceItemWrap .itemWrap .innerData .dataWrap .readMore svg{margin:-4px auto 0;}
.serviceItemWrap .itemWrap .innerData .dataWrap .readMore svg path{fill:#701A75;}
.serviceItemWrap .itemWrap .innerData:hover .dataWrap .readMore{background-color:#701A75;}
.serviceItemWrap .slick-arrow{position:absolute;bottom:-62px;background-color:#ffffff!important;color:transparent;width:40px;height:40px;border-radius:100%;display:flex!important;align-items:center;justify-content:center;flex-flow:row wrap;}
.serviceItemWrap .itemWrap .innerData:hover .dataWrap .readMore svg path{fill:#ffffff;}
.serviceItemWrap .slick-prev{left:60px;}
.serviceItemWrap .slick-next{right:96px;}
.serviceItemWrap .slick-arrow:before{content:"";background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='512' height='512' x='0' y='0' viewBox='0 0 492 492' style='enable-background:new 0 0 512 512' xml:space='preserve'><g><path d='M198.608 246.104 382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z' fill='%23701A75' opacity='1' data-original='%23701A75'></path></g></svg>");width:14px;height:14px;position:absolute;background-size:cover;}
.serviceItemWrap .slick-arrow.slick-prev{left:-4rem;}
.serviceItemWrap .slick-arrow.slick-next{right:-4rem;}
.serviceItemWrap .slick-arrow.slick-next:before{transform:rotate(-180deg);}

.serviceItemWrap .main{margin:20px auto 0;width:500px;height:500px;position:relative;}
ul.bigCircle{height:100%;width:100%;position:relative;border-radius:100%;display:flex;align-items:center;justify-content:center;}
ul.bigCircle:after{content:"";width:100%;height:100%;position:absolute;top:0;left:0;border:1px dashed #d6b1d6;border-radius:100%;-webkit-animation:rotate 50s both linear infinite;animation:rotate 50s both linear infinite;}
li.iconBlock{width:64px;height:64px;position:absolute;border-radius:50%;display:flex;align-items:center;justify-content:center;background-color:#ffffff;z-index:9;transition:width 0.3s linear, height 0.3s linear, box-shadow 0.3s linear;}
li.iconBlock svg{width:36px;height:36px;}
li.iconBlock svg path{opacity:0.6;}
ul.bigCircle li.iconBlock:nth-child(3) svg path,
ul.bigCircle li.iconBlock:nth-child(5) svg path{opacity:1;}
li.iconBlock:hover{box-shadow:0px 4px 4px 0px rgba(0,0,0,0.1);width:70px;height:70px;}
.iconBlock img{margin:0px auto;width:86%;}
ul.bigCircle li.iconBlock:nth-child(1){top:-32px;left:50%;transform:translateX(-50%);}
ul.bigCircle li.iconBlock:nth-child(2){top:50px;right:32px;}
ul.bigCircle li.iconBlock:nth-child(3){top:50%;right:-32px;transform:translateY(-50%);}
ul.bigCircle li.iconBlock:nth-child(4){bottom:46px;right:38px;}
ul.bigCircle li.iconBlock:nth-child(5){bottom:-32px;left:50%;transform:translateX(-50%);}
ul.bigCircle li.iconBlock:nth-child(6){bottom:46px;left:38px;}
ul.bigCircle li.iconBlock:nth-child(7){top:50%;left:-32px;transform:translateY(-50%);}
ul.bigCircle li.iconBlock:nth-child(8){top:50px;left:32px;}
ul.circle{position:absolute;width:70%;height:70%;top:50%;left:50%;transform:translate(-50%, -50%);}
ul.circle:after{content:"";width:100%;height:100%;position:absolute;top:0;left:0;border:1px dashed #d6b1d6;border-radius:100%;-webkit-animation:rotateRev 50s both linear infinite;animation:rotateRev 50s both linear infinite;}
ul.circle li.iconBlock:nth-child(1){top:-20px;left:77px;}
ul.circle li.iconBlock:nth-child(2){top:-20px;right:77px;}
ul.circle li.iconBlock:nth-child(3){top:77px;right:-20px;}
ul.circle li.iconBlock:nth-child(4){bottom:77px;right:-20px;}
ul.circle li.iconBlock:nth-child(5){bottom:-20px;right:77px;}
ul.circle li.iconBlock:nth-child(6){bottom:-20px;left:77px;}
ul.circle li.iconBlock:nth-child(7){bottom:77px;left:-20px;}
ul.circle li.iconBlock:nth-child(8){top:77px;left:-20px;}
@keyframes rotate{
  to{transform:rotate(360deg);}
}
@keyframes rotateRev{
    to{transform:rotate(-360deg);}
}
.serviceItemWrap .main .centerLogo{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);opacity:0.8;}
ul.bgcloudList{width:100%;height:100%;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);}
ul.bgcloudList li{position:absolute;padding:0 30px;}
ul.bgcloudList li:nth-child(1){top:-10px;left:20px;}
ul.bgcloudList li:nth-child(2){top:20px;right:48px;}
ul.bgcloudList li:nth-child(3){top:46%;transform:translateY(-50%);left:60px;}
ul.bgcloudList li:nth-child(4){top:50%;transform:translateY(-50%);right:-4px;}
ul.bgcloudList li:nth-child(5){bottom:40px;left:10;}
ul.bgcloudList li:nth-child(6){bottom:0px;right:40px;}
ul.bgcloudList li svg{width:160px;height:160px;}
ul.bgcloudList li svg path,
ul.bgcloudList li svg circle{fill:#ffffff;opacity:0.06;}
/*---------HomePage Industries---------*/
.industriesWrap{position:relative;width:100%;margin:0 auto;overflow:hidden;}
.industriesWrap .titleWrap{position:absolute;z-index:9;top:30px;left:60px;}
.industriesWrap .titleWrap h2{color:#ffffff;font-size:4.2rem;font-weight:900;text-transform:capitalize;margin:0 0 10px;}
.industriesWrap .titleWrap p{color:#ffffff;max-width:80%;width:100%;font-size:1.6rem;font-weight:300;letter-spacing:0.1px;line-height:28px;}
.industriesWrap .mar0px{margin:0 auto;}
.industriesWrap .pad0px{padding:0;}
.industriesWrap .imgsWrap{height:560px;}
.industriesWrap .imgsWrap .imgs{width:100%;height:100%;background-repeat:no-repeat;background-size:cover;}
.industriesWrap .itemSlider{height:560px;margin:-568px 0 0;width:100%;}
.industriesWrap .itemSlider .itemWrap{padding:0 1px;}
.industriesWrap .itemSlider .itemWrap .dataWrap{height:560px;background-color:rgba(0,0,0,0.5);display:flex;flex-flow:column;align-items:flex-start;justify-content:flex-end;padding:30px 30px 50px;}
.industriesWrap .slick-slide.slick-active.slick-current .itemWrap .dataWrap{background-color:rgba(0,0,0,0.7);}
.industriesWrap .itemSlider .itemWrap .dataWrap h2{color:#ffffff;font-size:2.2rem;font-weight:900;letter-spacing:0.1px;transition:top ease 0.5s;}
.industriesWrap .itemSlider .itemWrap .dataWrap p{color:#ffffff;font-size:1.6rem;font-weight:400;letter-spacing:0.1px;line-height:26px;margin:0;transition:top ease 0.5s;}
.industriesWrap .itemSlider .itemWrap .dataWrap a{color:#701A75;background-color:rgba(255,255,255,0.7);border-radius:30px;font-size:1.2rem;font-weight:500;display:inline-block;padding:0px 12px;margin:10px 0 0;transition:transform linear 300ms;}
.industriesWrap .slick-slide.slick-active.slick-current .itemWrap .dataWrap h2,
.industriesWrap .slick-slide.slick-active.slick-current .itemWrap .dataWrap p,
.industriesWrap .slick-slide.slick-active.slick-current .itemWrap .dataWrap a{transform:translate(0, -60px);}
.industriesWrap .itemSlider .itemWrap .dataWrap a:hover{background-color:#ffffff;}
.industriesWrap .slick-arrow{position:absolute;bottom:-62px;background-color:#ffffff!important;color:transparent;width:40px;height:40px;border-radius:100%;display:flex!important;align-items:center;justify-content:center;flex-flow:row wrap;z-index:9;}
.industriesWrap .slick-prev{left:60px;}
.industriesWrap .slick-next{right:96px;}
.industriesWrap .slick-arrow:before{content:"";background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='512' height='512' x='0' y='0' viewBox='0 0 492 492' style='enable-background:new 0 0 512 512' xml:space='preserve'><g><path d='M198.608 246.104 382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z' fill='%23701A75' opacity='1' data-original='%23701A75'></path></g></svg>");width:14px;height:14px;position:absolute;background-size:cover;}
.industriesWrap .slick-arrow.slick-next:before{transform:rotate(-180deg);}
/*---------HomePage Product---------*/
.productWrap{width:100%;padding:60px 0;margin:0 auto;background-color:#f3f3f3;}
.productWrap .titleWrap{padding:0 80px 0 0;}
.productWrap .titleWrap h2{color:#000000;font-size:4.2rem;font-weight:900;text-transform:capitalize;margin:0 0 10px;}
.productWrap .titleWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;margin:0;letter-spacing:0.2px;font-weight:400;}
.productWrap .titleWrap a{background-color:#701A75;color:#ffffff;padding:6px 22px 2px;border-radius:30px;font-size:1.2rem;display:inline-block;margin:35px 0 0;font-weight:600;text-transform:uppercase;letter-spacing:0.4px;}
.productsList .itemWrap{padding:10px;}
.productsList .itemWrap .innerWrap{background-color:#ffffff;border-radius:10px;padding:35px 26px 15px;box-shadow:0px 4px 4px 0px rgba(0,0,0,0.1);position:relative;}
.productsList .itemWrap:hover .innerWrap{background-color:#701A75;}
.productsList .itemWrap .innerWrap .icoWrap{margin:0 0 22px;}
.productsList .itemWrap:hover .innerWrap .icoWrap .onHover,
.productsList .itemWrap .innerWrap .icoWrap .noHover{display:inline-block;}
.productsList .itemWrap .innerWrap .icoWrap .onHover,
.productsList .itemWrap:hover .innerWrap .icoWrap .noHover{display:none;}
.productsList .itemWrap:hover .innerWrap .icoWrap svg path{fill:#ffffff;}
.productsList .itemWrap .innerWrap .icoWrap.widLess{height:64px;}
.productsList .itemWrap .innerWrap .icoWrap.widLess .onHover,
.productsList .itemWrap .innerWrap .icoWrap.widLess .noHover{width:130px;}
.productsList .itemWrap .innerWrap .dataWrap h2{color:#000000;font-size:1.8rem;font-weight:700;}
.productsList .itemWrap .innerWrap .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:26px;margin:0;letter-spacing:0.2px;font-weight:400;min-height:160px;}
.productsList .itemWrap:hover .innerWrap .dataWrap h2,
.productsList .itemWrap:hover .innerWrap .dataWrap p{color:#ffffff;}
.productsList .itemWrap .innerWrap .dataWrap .readMore{margin:20px 0 0;display:flex;justify-content:end;position:absolute;right:18px;bottom:18px;}
.productsList .itemWrap .innerWrap .dataWrap .readMore a{background-color:#701A75;width:34px;height:34px;border-radius:100%;
display:flex;align-items:center;justify-content:center;}
.productsList .itemWrap:hover .innerWrap .dataWrap .readMore a{background-color:#ffffff;}
.productsList .itemWrap:hover .innerWrap .dataWrap .readMore a svg path,
.productsList .itemWrap:hover .innerWrap .dataWrap .readMore a svg circle{fill:#701A75;}
.productsList .slick-arrow{position:absolute;bottom:-62px;background-color:#701A75!important;color:transparent;width:30px;height:30px;border-radius:100%;display:flex!important;align-items:center;justify-content:center;flex-flow:row wrap;}
.productsList .slick-prev{left:60px;}
.productsList .slick-next{right:96px;}
.productsList .slick-arrow:before{content:"";background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='512' height='512' x='0' y='0' viewBox='0 0 492 492' style='enable-background:new 0 0 512 512' xml:space='preserve'><g><path d='M198.608 246.104 382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z' fill='%23ffffff' opacity='1' data-original='%23ffffff'></path></g></svg>");width:14px;height:14px;position:absolute;background-size:cover;}
.productsList .slick-arrow.slick-prev{left:-30px;}
.productsList .slick-arrow.slick-next{right:-30px;}
.productsList .slick-arrow.slick-next:before{transform:rotate(-180deg);}
/*---------HomePage Why Choose Us---------*/
.whyUsWrap{width:100%;margin:0 auto;padding:60px 0 60px;background-color:#ffffff;}
.whyUsWrap .leftWrap{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;position:relative;}
.whyUsWrap .leftWrap:before{content:"";background-image:url(../img/shape-2.png);background-repeat:no-repeat;width:140px;height:172px;background-size:cover;position:absolute;left:-30px;top:-30px;z-index:1;}
.whyUsWrap .topDataWrap{border:3px solid #0F172A;border-radius:10px;background-color:#ffffff;max-width:90%;margin:0 0 20px;padding:10px;}
.whyUsWrap .topDataWrap span{color:#1E40AF;background-color:#DBEAFE;padding:4px 8px;border-radius:4px;font-size:1.2rem;font-weight:500;display:inline-block;line-height:20px;}
.whyUsWrap .topDataWrap h2{color:#0F172A;font-size:2rem;font-weight:600;line-height:24px;letter-spacing:0.1px;margin:4px 0;}
.whyUsWrap .topDataWrap p{color:#475569;margin:0;font-size:1.4rem;line-height:24px;letter-spacing:0.2px;}
.whyUsWrap .leftPannel{width:50%;padding:0 20px 0 0;display:flex;flex-flow:column;align-items:flex-end;position:relative;z-index:9;}
.whyUsWrap .leftPannel .imgWrap{text-align:right;position:relative;}
.whyUsWrap .leftPannel .imgWrap:before{content:"";background-image:url(../img/shape-3.png);background-repeat:no-repeat;background-size:cover;width:80px;height:80px;position:absolute;left:-70px;top:-10px;z-index:-1;}
.whyUsWrap .leftWrap .videoWrap{position:relative;width:100%;height:350px;}
.whyUsWrap .leftWrap .videoWrap iframe{position:absolute;width:100%;height:100%;position:relative;z-index:9;}
.whyUsWrap .rightPannel{width:50%;position:relative;z-index:9;}
.whyUsWrap .rightWrap{padding:0 20px;}
.whyUsWrap .titleWrap{margin:0 0 35px;text-align:center;}
.whyUsWrap .titleWrap span{color:#701A75;font-size:1.5rem;font-weight:600;} 
.whyUsWrap .titleWrap h2{color:#000000;font-weight:800;font-size:3.6rem;line-height:48px;}
.whyUsWrap .rightWrap .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;margin:0;letter-spacing:0.2px;font-weight:400;}
.whyUsWrap .rightWrap .dataWrap h6{color:#0F172A;font-size:1.6rem;line-height:30px;margin:0;letter-spacing:0.2px;font-weight:500;margin:24px 0 0;position:relative;padding:8px 0 0;}
.whyUsWrap .rightWrap .dataWrap h6:before{content:"";width:100px;height:4px;background-color:#FBBF24;border-radius:3px;position:absolute;top:0;left:0;}
/*---------HomePage Testimonials---------*/
.testimonialsWrap{width:100%;padding:50px 0 90px;margin:0 auto;background-color:#fafafa;}
.testimonialsWrap .titleWrap{text-align:center;margin:0 auto 30px;}
.testimonialsWrap .titleWrap h2{color:#000000;font-size:3.4rem;font-weight:900;text-transform:capitalize;margin:0 0 10px;}
.testimonialsWrap .itemWrap .innerWrap{background-color:#ffffff;display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:space-between;}
.testimonialsWrap .itemWrap .icoWrap{width:280px;}
.testimonialsWrap .itemWrap .icoWrap img{width:100%;height:100%;object-fit:cover;}
.testimonialsWrap .itemWrap .dataWrap{width:calc(100% - 280px);padding:35px;}
.testimonialsWrap .itemWrap .dataWrap .topWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;margin:0 0 30px;}
.testimonialsWrap .itemWrap .dataWrap .topWrap .leftWrap h2{font-size:2.2rem;font-weight:800;margin:0 0 2px;}
.testimonialsWrap .itemWrap .dataWrap .topWrap .leftWrap span{display:inline-block;color:#666666;font-size:1.5rem;letter-spacing:0.2px;}
.testimonialsWrap .itemWrap .dataWrap .topWrap .rightWrap{width:160px;}
.testimonialsWrap .itemWrap .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;margin:0;letter-spacing:0.2px;font-weight:400;}
.testimonialsWrap .slick-dots{display:flex!important;justify-content:center;align-items:center;}
.testimonialsWrap .slick-dots li{padding:0!important;margin:0;width:auto;}
.testimonialsWrap .slick-dots li button{width:6px;height:6px;margin:20px 6px 0px;border-radius:30px;display:inline-block;border-top-right-radius:0;background-color:#787878;-webkit-transition:0.3s all ease-in-out;transition:0.3s all ease-in-out;color:transparent;}
.testimonialsWrap .slick-dots li.slick-active button{width:35px;background-color:#701A75;}
.testimonialsWrap .slick-dots li button:before{content:none;}
/*---------HomePage OUR PARTNERS---------*/
.ourPartnerWrap{width:100%;padding:60px 0;margin:0 auto;background-color:#ffffff;}
.ourPartnerWrap .titleWrap{text-align:center;margin:0 auto 50px;}
.ourPartnerWrap .titleWrap h2{color:#000000;font-size:4.2rem;font-weight:900;text-transform:capitalize;margin:0 auto;}
.ourPartnerWrap ul{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;}
.ourPartnerWrap ul li{width:18%;position:relative;}
.ourPartnerWrap ul li:before,
.ourPartnerWrap ul li:after{content:"";background-color:#E4E4E7;width:100%;height:1px;position:absolute;bottom:0;left:0;}
.ourPartnerWrap ul li:after{width:1px;height:100%;top:0;bottom:auto;right:0;left:auto;}
.ourPartnerWrap ul li:nth-child(5):after,
.ourPartnerWrap ul li:nth-child(6):before,
.ourPartnerWrap ul li:nth-child(7):before,
.ourPartnerWrap ul li:nth-child(8):before,
.ourPartnerWrap ul li:nth-child(9):before,
.ourPartnerWrap ul li:nth-child(10):before,
.ourPartnerWrap ul li:nth-child(10):after{content:none;}
/*---------HomePage Recommended For You---------*/
.recommendWrap{width:100%;padding:60px 0 180px;margin:0 auto -120px;background-color:#fafafa;}
.recommendWrap .titleWrap{text-align:center;margin:0 auto 40px;}
.recommendWrap .titleWrap h2{color:#000000;font-size:4.2rem;font-weight:900;text-transform:capitalize;margin:0 0 10px;}
.recommendWrap .itemWrap{border-radius:10px;box-shadow:0 5px 45px rgba(35,55,86,.2);background-color:#ffffff;padding:20px;display:flex;justify-content:space-between;position:relative;margin:0 36px 0 0;}
.recommendWrap .itemWrap .dataWrap{display:flex;justify-content:space-between;flex-flow:column;width:60%;}
.recommendWrap .itemWrap .dataWrap p{margin:0;color:#0F172A;}
.recommendWrap .itemWrap .dataWrap h2{margin:16px 0;font-size:1.6rem;font-weight:600;line-height:24px;color:#000000;}
.recommendWrap .itemWrap .dataWrap a{display:inline-block;color:#0F172A;font-size:1.3rem;font-weight:500;}
.recommendWrap .itemWrap .dataWrap a:hover{color:#701A75;}
.recommendWrap .itemWrap .imgWrap{width:110px;position:absolute;right:-20px;top:50%;transform:translateY(-50%);height:100px;border-left:6px solid #701A75;}
.recommendWrap .itemWrap .imgWrap img{width:100%;height:100%;object-fit:cover;}
/*---------InnerPage Banner---------*/
.innerBanner{background-color:#701A75;padding:120px 0 80px;margin:0 auto!important;box-shadow:none;}
.innerBanner:before,
.innerBanner:after{content:none;}
.innerBanner .titleWrap{text-align:center;position:relative;z-index:99;}
.innerBanner .titleWrap h2{color:#ffffff;font-weight:800;font-size:4.2rem;margin:0 auto 8px;}
.innerBanner .titleWrap p{color:#ffffff;font-size:1.5rem;font-weight:400;letter-spacing:0.2px;line-height:30px;margin:0 auto;}
.innerBanner .titleWrap a{display:inline-block;margin:10px auto 0;color:#000000;background-color:#ffffff;border-radius:30px;padding:8px 20px;font-size:1.3rem;font-weight:500;}

.innerBanner .videoWrap{position:absolute;border-radius:0px;overflow:hidden;height:100%;width:100%;top:0;}
.innerBanner .videoWrap:before{content:"";width:100%;height:100%;position:absolute;bottom:0px;background-position:center center;left:50%;transform:translateX(-50%);z-index:1;background-color:#701a7582;}
.innerBanner .videoWrap video{width:100%;border-radius:0px;height:100%;position:absolute;object-fit:cover;object-position:bottom;}
.innerBanner .videoWrap.video3 video{object-position:center;}

.CommonBtmWrap{width:100%;margin:0 auto;padding:0 0 60px;background-color:#ffffff;position:relative;overflow:hidden;}
.CommonBtmWrap .dataWrap{position:relative;z-index:2;text-align:center;}
.CommonBtmWrap .dataWrap h2{font-size:2.6rem;font-weight:600;margin:0 auto 20px;color:#000000;letter-spacing:0.2px;}
.CommonBtmWrap .dataWrap p{color:#0F172A;margin:0 auto;font-size:1.6rem;letter-spacing:0.2px;line-height:30px;font-weight:300;}    
.topPageBanner{background-color:#701A75;padding:120px 0 80px;margin:0 auto!important;box-shadow:none;}
.topPageBanner .titleWrap{text-align:center;position:relative;z-index:9;}
.topPageBanner .titleWrap h2{color:#ffffff;font-weight:800;font-size:4.2rem;margin:0 auto 6px;}
.topPageBanner .titleWrap p{color:#ffffff;font-size:1.5rem;font-weight:400;letter-spacing:0.2px;line-height:30px;margin:0 auto;}
.innerPageIntroData{width:100%;padding:40px 0 30px;margin:0 auto;background-color:#fafafa;}
.innerPageIntroData .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 0 16px;}
.innerPageIntroData .titleWrap h2{color:#000000;font-weight:800;font-size:4.2rem;margin:0 auto 6px;}
.innerPageIntroData .titleWrap p{color:#FBBF24;font-size:1.6rem;font-weight:700;margin:0 0 15px;}
.aipageDataWrap.innerPageIntroData{padding:100px 0 40px;}
.aipageDataWrap .dataWrap p{font-size:1.6rem;line-height:32px;margin:0;}
.innerPageIntroData ul.aiMlList{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;padding:0 0 0 60px;}
.innerPageIntroData ul.aiMlList li{width:31.66%;}
.innerPageIntroData ul.aiMlList li:nth-child(2){margin:20px 0 0;}
.innerPageIntroData ul.aiMlList li:nth-child(3){margin:40px 0 0;}
.innerPageIntroData ul.aiMlList li .itemWrap{padding:30px 8px;background:#ffffff;margin:0 auto 16px;border-radius:10px;box-shadow:0 0 30px rgba(0,0,0,0.12);text-align:center;}
.innerPageIntroData ul.aiMlList li .itemWrap.clr1{background:linear-gradient(to bottom, rgba(0, 160, 255, 0.41) 0, rgba(39, 84, 130, 0.41) 100%);}
.innerPageIntroData ul.aiMlList li .itemWrap.clr2{background:linear-gradient(to bottom,rgba(203,156,238,.41) 0,rgba(197,130,214,.41) 100%);}
.innerPageIntroData ul.aiMlList li .itemWrap.clr3{background:linear-gradient(to bottom, rgba(156, 238, 189, .41) 0, rgba(170, 200, 179, .41) 100%);}
.innerPageIntroData ul.aiMlList li .itemWrap.clr4{background:linear-gradient(to bottom,rgba(238,238,156,.41) 0,rgba(206,210,130,.41) 100%);}
.innerPageIntroData ul.aiMlList li .itemWrap.clr5{background:linear-gradient(to bottom,rgba(238,156,156,.41) 0,rgba(238,156,156,.41) 1%,rgba(239,171,173,.41) 100%);}
.innerPageIntroData ul.aiMlList li .itemWrap.clr6{background:linear-gradient(to bottom,rgba(156,205,238,.41) 0,rgba(123,157,170,.41) 100%);}
.innerPageIntroData ul.aiMlList li .itemWrap .imgWrap{width:100px;height:100px;display:flex;align-items:center;justify-content:center;text-align:center;margin:0 auto;}
.innerPageIntroData ul.aiMlList li .itemWrap .imgWrap img{width:100%;height:100%;object-fit:contain;}
.innerPageIntroData ul.aiMlList li .itemWrap .dataWrap{min-height:50px;margin:20px auto 0;} 
.innerPageIntroData ul.aiMlList li .itemWrap .dataWrap h2{color:#000000;font-size:1.6rem;margin:30px auto 0;font-weight:700;line-height:24px;} 

.crmpageListWrap.innerPageIntroData{padding:120px 0 40px;background-color:#ffffff;}
.crmpageListWrap.innerPageIntroData .padRightWrap{padding-left:50px;}
.crmpageListWrap.innerPageIntroData ul.aiMlList li{width:48.40%;}

.whyHumingtecSec{width:100%;padding:40px 0 60px;margin:0px auto 0;background-color:#ffffff;text-align:center;}
.whyHumingtecSec .titleWrap h2{color:#000000;font-weight:800;font-size:2.8rem;margin:0 auto 15px;}
.whyHumingtecSec .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0;}
/*---------Industires---------*/
/* .heathCareWrap,
.manuFacturWrap,
.mobilityBanner,
.HospitalityBanner,
.fashionBanner,
.educationBanner,
.smbBanner,
.enterpCustWrap,
.TestingBanner,
.startUpBanner{background-position:center;background-size:cover;background-repeat:no-repeat;position:relative;}
.heathCareWrap:after,
.manuFacturWrap:after,
.mobilityBanner:after,
.HospitalityBanner:after,
.fashionBanner:after,
.educationBanner:after,
.smbBanner:after,
.enterpCustWrap:after,
.TestingBanner:after,
.startUpBanner:after{content:"";background-color:#701a7580;position:absolute;height:100%;width:100%;top:0;left:0;}
.heathCareWrap{background-image:url(../img/Healthcare.jpeg);}
.manuFacturWrap{background-image:url(../img/Manufacturing.png);}
.mobilityBanner{background-image:url(../img/Mobillity.png);}
.HospitalityBanner{background-image:url(../img/Hospitality.png);}
.fashionBanner{background-image:url(../img/Fashion.png);}
.educationBanner{background-image:url(../img/Education.png);}
.smbBanner{background-image:url(../img/Manufacturing.png);}
.enterpCustWrap{background-image:url(../img/Manufacturing.png);}
.TestingBanner{background-image:url(../img/Manufacturing.png);}
.startUpBanner{background-image:url(../img/Manufacturing.png);} */

.fashionWrap .dataWrap ul{margin:14px 0 20px;}
.fashionWrap .dataWrap.sticyDiv ul li{margin:0 0 7px;} 
.fashionWrap .dataWrap ul li:last-child{margin:0;}
.fashionWrap .titleWrap ul li{color:#0F172A;margin:0 auto 10px;font-size:1.6rem;line-height:26px;letter-spacing:0.1px;}
.fashionWrap .itemWrap .dataWrap ul{margin:12px 0 0;}
.fashionWrap .itemWrap .dataWrap ul li{padding:0 0 0 12px;position:relative;color:#0F172A;font-size:1.5rem;font-weight:400;margin:0 0 10px;line-height:24px;}
.fashionWrap .itemWrap .dataWrap ul li:before{content:"";width:5px;height:5px;background-color:#701A75;border-radius:100%;position:absolute;left:0px;top:10px;}
.fashionBtmWrap{width:100%;padding:0 0 80px;background-color:#ffffff;margin:0 auto;}
.fashionBtmWrap .itemWrap{padding:30px 20px;height:100%;}
.fashionBtmWrap .itemWrap.bgClr1{background-color:#BB6BD929;}
.fashionBtmWrap .itemWrap.bgClr2{background-color:#2F80ED29;}
.fashionBtmWrap .itemWrap.bgClr3{background-color:#27AE6029;}
.fashionBtmWrap .itemWrap.bgClr4{background-color:#e0182729;}
.fashionBtmWrap .itemWrap h2{color:#000000;font-weight:600;font-size:2rem;line-height:28px;}
.fashionBtmWrap .itemWrap ul{margin:12px 0 0;}
.fashionBtmWrap .itemWrap ul li{padding:0 0 0 12px;position:relative;color:#0F172A;font-size:1.5rem;font-weight:400;margin:0 0 10px;line-height:24px;}
.fashionBtmWrap .itemWrap ul li:last-child{margin:0;}
.fashionBtmWrap .itemWrap ul li:before{content:"";width:5px;height:5px;background-color:#0F172A;border-radius:100%;position:absolute;left:0px;top:10px;}

.crmWrap.commonCotentWrap{padding:120px 0 60px;}
.crmWrap.commonCotentWrap .titleWrap{text-align:center;margin:0 auto 40px;}
.crmWrap.commonCotentWrap .titleWrap h2{margin:0 auto 10px;}
.digiTranformWrap.commonCotentWrap{padding:60px 0;}
.commonCotentWrap,
.aiMlWrap.commonCotentWrap{padding:60px 0;position:relative;}
.commonCotentWrap .bredCrumbsWrap{position:absolute;display:flex;flex-flow:row wrap;align-items:center;width:100%;background-color:#d9d9d9;left:0;top:0;padding:2px 64px;}
.commonCotentWrap .bredCrumbsWrap li{font-size:1.4rem;font-weight:600;margin:0 10px 0 0;padding:0 10px 0 0;color:#969696;}
.commonCotentWrap .bredCrumbsWrap li a{position:relative;color:#000000;}
.commonCotentWrap .bredCrumbsWrap li:hover a{color:#701A75;}
.commonCotentWrap .bredCrumbsWrap li a:after{content:"";background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='512' height='512' x='0' y='0' viewBox='0 0 492 492' style='enable-background:new 0 0 512 512' xml:space='preserve'><g><path d='M198.608 246.104 382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z' fill='%23701A75' opacity='1' data-original='%23701A75'></path></g></svg>");width:10px;height:10px;position:absolute;background-size:cover;transform:rotate(-180deg); right:-15px;top:4px;}

.crmWrap.commonCotentWrap .videoWrap{border-radius:4px;flex-direction:column;justify-content:center;display:flex;position:sticky;top:20vh;}
.mobileSecWrap .videoWrap .video,
.crmWrap.commonCotentWrap .videoWrap .video{position:relative;}
.mobileSecWrap .videoWrap .video video,
.crmWrap.commonCotentWrap .videoWrap .video video{width:100%;background-color:#ffffff;padding:5px;}
.crmWrap.commonCotentWrap .masonaryWrap.padLeft{padding-left:30px;}
.customAppDevWrap .videoWrap{border-radius:4px;flex-direction:column;justify-content:center;display:flex;padding:0;position:relative;margin:0 0 25px;}
.mobileSecWrap .videoWrap svg,
.crmWrap.commonCotentWrap .videoWrap svg,
.customAppDevWrap .videoWrap svg{width:380px;z-index:1;top:-36px!important;}
.mobileSecWrap .videoWrap video,
.crmWrap.commonCotentWrap .videoWrap video,
.customAppDevWrap .videoWrap video{width:100%;object-position:top;max-width:100%;box-shadow:0px 5px 36px rgba(0,0,0,.06);border-radius:12px;position:relative;z-index:9;}
.mobileBanner{padding:120px 0 50px;position:relative;min-height:310px;display:flex;align-items:center;justify-content:center;}
.mobileBanner.innerBanner .titleWrap h2{margin:0 auto;}
.mobileBanner.innerBanner .videoWrap{border-radius:4px;flex-direction:column;justify-content:center;display:flex;padding:0;}
.mobileBanner.innerBanner .videoWrap video{width:100%;object-position:top;}
.aboutSectionWrap.mobApp,
.digitalPageWrap{background-color:#ffffff;}

.aiDrivenWrap{padding:0!important;}
.marTp0,
.staffAugBenefitsWrap.benefitsDigiWrap{margin:0 auto!important;}
.staffAugItroWrap{width:100%;margin:0 auto;padding:120px 0 20px;}
.staffAugItroWrap .imgWrap{position:relative;text-align:center;}
.staffAugItroWrap .imgWrap:before{content:"";width:380px;height:380px;background-color:#f7f8ff;border-radius:100%;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);z-index:-1;}
.staffAugItroWrap .imgWrap:after{content:"";position:absolute;right:70px;top:-70px;width:380px;height:380px;border:1px solid #701A75;border-radius:100%;}
.staffAugItroWrap .imgWrap img{width:64%;margin:0 auto;text-align:center;position:relative;z-index:1;}
.staffAugItroWrap .dataWrap.dataWrapPadLt{padding:0 0 0 50px;}
.staffAugItroWrap .dataWrap.dataWrapPadRt{padding:0 50px 0 0;}
.staffAugItroWrap .dataWrap h2{font-size:2.8rem;line-height:40px;margin:0 0 20px;font-weight:800;}
.staffAugItroWrap .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 0 15px;}
.staffAugBenefitsWrap{width:100%;padding:60px 0 70px;margin:90px auto 0;background-color:#fafafa;}
.staffAugBenefitsWrap.nodejsBenefitsWrap{margin:0 auto;} 
.staffAugBenefitsWrap.gptBenefitsWrap{margin:0 auto;}
.staffAugBenefitsWrap .ttlWrap{text-align:center;margin:0 auto 40px;}
.staffAugBenefitsWrap .ttlWrap h2{color:#000000;font-weight:800;font-size:2.8rem;}
.staffAugBenefitsWrap .ttlWrap p{margin:0 auto;color:#585858;font-size:1.6rem;line-height:26px;letter-spacing:0.1px;}
.staffAugBenefitsWrap ul{display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:space-between;}
.staffAugBenefitsWrap ul li{width:20%;padding:0 12px;}
.staffAugBenefitsWrap.javaBenefitsWrap{margin:0 auto;}
.staffAugBenefitsWrap.pyBenefitsWrap{margin:0 auto;}
.staffAugBenefitsWrap.gptBenefitsWrap ul li,
.staffAugBenefitsWrap.benefitsCrmWrap ul li,
.staffAugBenefitsWrap.benefitsDigiWrap ul li,
.staffAugBenefitsWrap.aiPBenefitsWrap ul li,
.staffAugBenefitsWrap.javaBenefitsWrap ul li,
.staffAugBenefitsWrap.nodejsBenefitsWrap ul li{width:25%;}
.staffAugBenefitsWrap ul li .itemWrap{background-color:#ffffff;border-radius:8px;padding:26px 12px;}
.staffAugBenefitsWrap ul li:nth-child(1) .itemWrap{background-image:linear-gradient(to bottom,rgba(156,238,189,.41) 0,rgba(170,200,179,.41) 100%);}
.staffAugBenefitsWrap ul li:nth-child(2) .itemWrap{background-image:linear-gradient(to bottom,rgba(156,205,238,.41) 0,rgba(123,157,170,.41) 100%);}
.staffAugBenefitsWrap ul li:nth-child(3) .itemWrap{background-image:linear-gradient(to bottom,rgba(203,156,238,.41) 0,rgba(197,130,214,.41) 100%);}
.staffAugBenefitsWrap ul li:nth-child(4) .itemWrap{background-image:linear-gradient(to bottom,rgba(238,238,156,.41) 0,rgba(206,210,130,.41) 100%);}
.staffAugBenefitsWrap ul li:nth-child(5) .itemWrap{background-image:linear-gradient(to bottom,rgba(238,156,156,.41) 0,rgba(238,156,156,.41) 1%,rgba(239,171,173,.41) 100%);}
.staffAugBenefitsWrap ul li .itemWrap .imgWrap{text-align:center;margin:0 auto 16px;}
.staffAugBenefitsWrap ul li .itemWrap .imgWrap img{width:40px;}
.staffAugBenefitsWrap ul li .itemWrap .dataWrap{text-align:center;min-height:180px;}
.staffAugBenefitsWrap.aiPBenefitsWrap ul li .itemWrap .dataWrap{min-height:120px;}
.staffAugBenefitsWrap.aiChatBotBen ul li .itemWrap .dataWrap{min-height:140px;}
.staffAugBenefitsWrap.gptBenefitsWrap ul li .itemWrap .dataWrap,
.staffAugBenefitsWrap.benefitsDigiWrap ul li .itemWrap .dataWrap{min-height:150px;}
.staffAugBenefitsWrap.benefitsCrmWrap ul li .itemWrap .dataWrap{min-height:200px;}
.staffAugBenefitsWrap ul li .itemWrap .dataWrap h2{color:#000000;font-weight:700;font-size:1.6rem;line-height:28px;margin:0 auto;} 
.staffAugBenefitsWrap ul li .itemWrap .dataWrap p{margin:0 auto;color:#585858;font-size:1.5rem;line-height:26px;letter-spacing:0.1px;}
.staffAugValueWrap{width:100%;margin:0 auto;padding:140px 0 60px;}
.staffAugValueWrap.reactValueWap{margin:0 auto 60px;background-color:#fafafa;padding:120px 0 80px;}
.staffAugValueWrap .dataWrap{margin:-70px auto 0;}
.staffAugValueWrap .dataWrap ul li strong{font-weight:500;color:#000000;display:inline-block;margin:0 4px 0 0;}
.staffAugValueWrap .dataWrap ul li{color:#585858;font-size:1.6rem;margin:0 0 10px;letter-spacing:0.1px;line-height:30px;}
.staffOnBoardWrap{width:100%;margin:0 auto;padding:60px 0;background-color:#701A75;position:relative;}
.staffOnBoardWrap:after{content:"";background-image:url(../img/processLine.png);background-repeat:no-repeat;background-size:cover;width:100%;height:82px;position:absolute;bottom:264px;z-index:1;}
.staffAugValueWrap .dataWrap h3{color:#000000;font-weight:600;font-size:1.6rem;margin:16px 0 10px;}
.staffAugValueWrap .dataWrap .btmTags span{display:inline-block;background-color:rgba(112, 26, 117, 0.1);color:#701A75;font-size:1.2rem;font-weight:600;letter-spacing:0.2px;margin:0 6px 6px 0;padding:4px 10px;border-radius:6px;line-height:16px;}
.staffOnBoardWrap .ttlWrap{text-align:center;margin:0 auto 40px;}
.staffOnBoardWrap .ttlWrap h2{color:#ffffff;font-weight:800;font-size:2.8rem;}
.staffOnBoardWrap ul{display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:space-between;margin:40px auto 0;position:relative;z-index:9;}
.staffOnBoardWrap ul li{width:20%;padding:0 12px;}
.staffOnBoardWrap ul li:nth-child(2),
.staffOnBoardWrap ul li:nth-child(4){margin:40px auto 0;}
.staffOnBoardWrap ul li .itemWrap{background-color:#ffffff;border-radius:8px;padding:26px 12px;}
.staffOnBoardWrap ul li .itemWrap .noWrap{text-align:center;margin:0 auto 16px;display:flex;justify-content:center;align-items:center;width:60px;height:60px;border-radius:100%;}
.staffOnBoardWrap ul li:nth-child(1) .itemWrap .noWrap{background-image:linear-gradient(to bottom,rgba(156,238,189,.41) 0,rgba(170,200,179,.41) 100%);}
.staffOnBoardWrap ul li:nth-child(2) .itemWrap .noWrap{background-image:linear-gradient(to bottom,rgba(156,205,238,.41) 0,rgba(123,157,170,.41) 100%);}
.staffOnBoardWrap ul li:nth-child(3) .itemWrap .noWrap{background-image:linear-gradient(to bottom,rgba(203,156,238,.41) 0,rgba(197,130,214,.41) 100%);}
.staffOnBoardWrap ul li:nth-child(4) .itemWrap .noWrap{background-image:linear-gradient(to bottom,rgba(238,238,156,.41) 0,rgba(206,210,130,.41) 100%);}
.staffOnBoardWrap ul li:nth-child(5) .itemWrap .noWrap{background-image:linear-gradient(to bottom,rgba(238,156,156,.41) 0,rgba(238,156,156,.41) 1%,rgba(239,171,173,.41) 100%);}
.staffOnBoardWrap ul li .itemWrap .noWrap h2{color:#000000;font-weight:800;margin:0 auto;font-size:1.8rem;}
.staffOnBoardWrap ul li .itemWrap .dataWrap{text-align:center;min-height:180px;}
.staffOnBoardWrap ul li .itemWrap .dataWrap h2{color:#000000;font-weight:700;font-size:1.6rem;line-height:28px;margin:0 auto;} 
.staffOnBoardWrap ul li .itemWrap .dataWrap p{margin:0 auto;color:#585858;font-size:1.5rem;line-height:26px;letter-spacing:0.1px;}
.staffAugFaqWrap{width:100%;margin:0 auto;padding:60px 0;}
.staffAugFaqWrap .ttlWrap{text-align:left;margin:0 auto 40px;}
.staffAugFaqWrap .ttlWrap.ttlCenter{text-align:center;}
.staffAugFaqWrap .ttlWrap h2{color:#000000;font-weight:800;font-size:2.8rem;}
.staffAugFaqWrap .accordion{padding:0 30px 0 0;}
.staffAugFaqWrap .accordion .accordion-item{border:0;border-bottom:1px solid #DDDDDD;border-radius:0;padding:25px 20px 25px 40px;}
.staffAugFaqWrap .accordion .accordion-item .accordion-header .accordion-button{outline:0;border:0;border-radius:0;background-color:transparent;box-shadow:none;padding:0;color:#001404;font-size:1.8rem;font-weight:600;position:relative;}
.staffAugFaqWrap .accordion .accordion-item .accordion-header .accordion-button:before{content:"";background-image:url(../img/quote-4.png);background-repeat:no-repeat;background-size:cover;background-position:center;width:30px;height:30px;position:absolute;left:-40px;top:-6px;}
.staffAugFaqWrap .accordion .accordion-item .accordion-collapse .accordion-body{padding:15px 18px 0;}
.staffAugFaqWrap .accordion .accordion-item .accordion-collapse .accordion-body p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0;}
.staffAugFaqWrap .ht100p{height:100%;}
.staffAugFaqWrap .rightWrap{background-image:linear-gradient(-181deg, #fafafa, #F4F1F4);height:100%;padding:32px;}
.staffAugFaqWrap .rightWrap h2{color:#000000;font-weight:800;font-size:2.8rem;margin:0 0 16px;line-height:38px;}
.staffAugFaqWrap .rightWrap p{color:#0F172A;font-size:1.6rem;line-height:32px;letter-spacing:0.2px;margin:0;}
.staffAugFaqWrap .rightWrap p a{color:#701A75;font-weight:500;text-decoration:underline;display:inline-block;padding:0 4px;}
.staffAugValueWrap .imgWrap.martTop{margin:40px 0 0;}
.topPageServiceNav{width:100%;margin:0 auto;text-align:center;padding:10px 0;background-color:#e0e0e0;}
.topPageServiceNav ul{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;}
.topPageServiceNav ul li{margin:0 6px;}
.topPageServiceNav ul li a{display:inline-block;background-color:#ffffff;box-shadow:0px 4px 4px 0px rgba(0,0,0,0.1);padding:6px 20px;border-radius:30px;font-size:1.3rem;font-weight:600;color:#000000;line-height:16px;}
.topPageServiceNav ul li:hover a,
.topPageServiceNav ul li.active a{background-color:#701A75;color:#ffffff;}
.servTechIntro{width:100%;margin:0 auto;text-align:center;padding:60px 0;background-color:#fafafa;}
.servTechIntro .ttlWrap{text-align:center;margin:0 auto 20px;}
.servTechIntro .ttlWrap h2{color:#000000;font-weight:800;font-size:2.8rem;}
.servTechIntro .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0;}
.servCaseStudyWrap{width:100%;margin:0 auto;padding:60px 0 40px;background-color:#ffffff;}
.servCaseStudyWrap .ttlWrap{text-align:center;margin:0 auto 20px;}
.servCaseStudyWrap .ttlWrap h2{color:#000000;font-weight:800;font-size:2.8rem;}
.servCaseStudyWrap ul li{position:sticky;position:-webkit-sticky;height:295px;display:flex;align-items:flex-start;justify-content:center;margin:0 auto 50px;overflow:hidden;background-position:center;}
.servCaseStudyWrap.servCaseStudyWrapPy ul li{height:440px!important;}
.servCaseStudyWrap.nodeJsCsStudy ul li{height:360px!important;}
.servCaseStudyWrap ul li:nth-child(1){top:100px;}
.servCaseStudyWrap ul li:nth-child(2){top:160px;}
.servCaseStudyWrap ul li:nth-child(3){top:220px;}
.servCaseStudyWrap .itemWrap{text-align:left;margin:0 auto 16px;padding:30px;display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;}
.servCaseStudyWrap.nodeJsCsStudy ul li .itemWrap,
.servCaseStudyWrap.aimlChatBotCaseStudy ul li .itemWrap{height:100%;}
.servCaseStudyWrap .itemWrap .dataWrap{width:calc(100% - 40%);}
.servCaseStudyWrap .itemWrap .imgWrap{width:40%;padding:0 30px;}
.servCaseStudyWrap.servCaseStudyWrapPy .itemWrap{height:100%;}
.servCaseStudyWrap.servCaseStudyWrapPy .itemWrap .dataWrap{width:calc(100% - 35%);}
.servCaseStudyWrap.servCaseStudyWrapPy .itemWrap .imgWrap{width:35%;}
.servCaseStudyWrap .itemWrap.bgClr1{background-color:#eee2f3;}
.servCaseStudyWrap .itemWrap.bgClr2{background-color:#dfeafd;}
.servCaseStudyWrap .itemWrap.bgClr3{background-color:#e0f2e5;}
.servCaseStudyWrap .itemWrap h2{color:#000000;font-weight:800;font-size:1.8rem;line-height:28px;margin:0 0 16px;}
.servCaseStudyWrap .itemWrap p{color:#0F172A;margin:0 auto 12px;font-size:1.6rem;line-height:30px;letter-spacing:0.1px;}
.servDevBtmWrap{width:100%;margin:0 auto;padding:0 0 80px;}
.servDevBtmWrap .ht100p{height:100%;}
.servDevBtmWrap .leftWrap{padding:0 36px 0 0;}
.servDevBtmWrap .leftWrap h2{color:#000000;font-weight:800;font-size:2.8rem;margin:0 0 25px;}
.servDevBtmWrap .leftWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0;}
.servDevBtmWrap .rightWrap{background-image:linear-gradient(-181deg, #fbfbfb, #701a7526);height:100%;padding:30px;text-align:center;}
.servDevBtmWrap .rightWrap h2{color:#000000;font-weight:800;font-size:2.2rem;line-height:28px;margin:0 0 16px;}
.servDevBtmWrap .rightWrap p{color:#0F172A;margin:0 auto 30px;font-size:1.5rem;line-height:28px;letter-spacing:0.1px;text-align:center;}
.servDevBtmWrap .rightWrap a{background-color:#ffffff;color:#701a75;font-size:1.2rem;font-weight:700;letter-spacing:0.3px;outline:0;border:1px solid #701a75;box-shadow:none;padding:8px 16px;border-radius:30px;}
.servDevBtmWrap .rightWrap a:hover{background-color:#701a75;color:#ffffff;}
.dotNetValueWrap{width:100%;background-color:#fafafa;}
.aiDrivenIntro,
.aiDrivenInsdustWrap{padding:150px 0 90px!important;}


.digiTranformWrap .btmDataWrap{padding:32px 0 0;}
.digiTranformWrap .btmDataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0;}
.manuFacturListWrap{width:100%;margin:0 auto;padding:60px 0 80px;background-color:#ffffff;}
.manuFacturListWrap .pad0{padding:0;}
.manuFacturListWrap .itemWrap{padding:30px;position:relative;height:100%;}
.manuFacturListWrap .itemWrap:after,
.manuFacturListWrap .itemWrap:before{content:"";background-color:#E4E4E7;height:100%;width:1px;position:absolute;right:0;top:0;}
.manuFacturListWrap .itemWrap:before{width:100%;height:1px;bottom:0;left:0;top:auto;right:auto;}
.manuFacturListWrap .itemWrap.noContAfter:after,
.manuFacturListWrap .itemWrap.noContBefore:before{content:none;}
.manuFacturListWrap .itemWrap .icoWrap{text-align:center;margin:0 auto 10px;}
.manuFacturListWrap .itemWrap .dataWrap{text-align:center;}
.manuFacturListWrap .itemWrap .dataWrap h2{color:#000000;font-weight:600;font-size:2rem;line-height:28px;}
.manuFacturListWrap .itemWrap .dataWrap p{margin:0 auto;color:#0F172A;font-size:1.5rem;line-height:26px;letter-spacing:0.1px;}
.manufacturSecWrap{width:100%;background-color:#ffffff;padding:80px 0;}
.manufacturSecWrap .dataWrap{padding:0 50px 0 0;}
.manufacturSecWrap .dataWrap h2{color:#000000;font-size:3.2rem;font-weight:900;margin:0 0 25px;line-height:44px;}
.manufacturSecWrap .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 0 15px;}
.manufacturSecWrap .dataWrap ul li{color:#0F172A;font-size:1.6rem;margin:0 0 22px;font-weight:300;letter-spacing:0.1px;line-height:30px;}
.manufacturSecWrap .dataWrap ul li span{font-weight:500;display:inline-block;margin:0 4px 0 0;}

/*---------Capabilities---------*/
.commonBannerWrap{position:relative;width:100%;margin:0 auto;overflow:hidden;padding:0;}
.commonBannerWrap .titleWrap{position:absolute;z-index:9;top:100px;left:22px;}
.commonBannerWrap .titleWrap h2{color:#ffffff;font-size:3.8rem;font-weight:900;text-transform:capitalize;margin:0 0 10px;}
.commonBannerWrap .titleWrap p{color:#ffffff;max-width:100%;width:100%;font-size:1.6rem;font-weight:300;letter-spacing:0.1px;line-height:28px;}
.commonBannerWrap .mar0px{margin:0 auto;}
.commonBannerWrap .pad0px{padding:0;}
.commonBannerWrap .imgsWrap{height:360px;}
.commonBannerWrap .imgsWrap .imgs{width:100%;height:100%;background-repeat:no-repeat;background-size:cover;}
.commonBannerWrap .itemSlider{height:360px;margin:-370px 0 0;width:100%;}
.commonBannerWrap .itemSlider .itemWrap{padding:0 1px;}
.commonBannerWrap .itemSlider .itemWrap .dataWrap{height:360px;background-color:rgba(0,0,0,0.5);display:flex;flex-flow:column;align-items:flex-start;justify-content:flex-end;padding:30px 20px 30px;}
.commonBannerWrap .slick-slide.slick-active.slick-current .itemWrap .dataWrap{background-color:rgba(0,0,0,0.7);}
.commonBannerWrap .itemSlider .itemWrap .dataWrap h2{color:#ffffff;font-size:1.9rem;font-weight:800;letter-spacing:0.1px;transition:top ease 0.5s;}
.commonBannerWrap .itemSlider .itemWrap .dataWrap p{color:#ffffff;font-size:1.5rem;font-weight:400;letter-spacing:0.1px;line-height:25px;margin:0;transition:top ease 0.5s;}
.commonBannerWrap .itemSlider .itemWrap .dataWrap a{color:#701A75;background-color:rgba(255,255,255,0.7);border-radius:30px;font-size:1.2rem;font-weight:500;display:inline-block;padding:0px 12px;margin:16px 0 0;transition:transform linear 300ms;}
.commonBannerWrap .slick-slide.slick-active.slick-current .itemWrap .dataWrap h2,
.commonBannerWrap .slick-slide.slick-active.slick-current .itemWrap .dataWrap p,
.commonBannerWrap .slick-slide.slick-active.slick-current .itemWrap .dataWrap a{transform:translate(0, -20px);}
.commonBannerWrap .itemSlider .itemWrap .dataWrap a:hover{background-color:#ffffff;}
.commonBannerWrap .slick-arrow{position:absolute;top:50px;background-color:#ffffff!important;color:transparent;width:28px;height:28px;border-radius:100%;display:flex!important;align-items:center;justify-content:center;flex-flow:row wrap;z-index:9;}
.commonBannerWrap .slick-prev{left:92%;}
.commonBannerWrap .slick-next{right:50px;}
.commonBannerWrap .slick-arrow:before{content:"";background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='512' height='512' x='0' y='0' viewBox='0 0 492 492' style='enable-background:new 0 0 512 512' xml:space='preserve'><g><path d='M198.608 246.104 382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z' fill='%23701A75' opacity='1' data-original='%23701A75'></path></g></svg>");width:12px;height:12px;position:absolute;background-size:cover;}
.commonBannerWrap .slick-arrow.slick-next:before{transform:rotate(-180deg);}

.commonCotentWrap{width:100%;background-color:#ffffff;padding:60px 0 0;margin:0 auto;}
.commonCotentWrap .titleWrap h2{color:#000000;font-size:3.2rem;font-weight:900;margin:0 0 25px;}
.commonCotentWrap .titleWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0;}
.commonCotentWrap .titleWrap h3{margin:25px 0 0;color:#000000;font-size:1.8rem;font-weight:500;line-height:26px;}
.commonCotentWrap .titleWrap ul li{color:#0F172A;margin:0 auto 10px;font-size:1.6rem;line-height:26px;letter-spacing:0.1px;}
.commonCotentWrap .itemWrap .dataWrap ul{margin:12px 0 0;}
.commonCotentWrap .itemWrap .dataWrap ul li{padding:0;position:relative;color:#0F172A;font-size:1.5rem;font-weight:400;margin:0 0 10px;line-height:24px;}
.commonCotentWrap .itemWrap .dataWrap ul li strong{font-weight:600;}
.aimlBenefitsWrap{width:100%;background-color:#fafafa;padding:60px 0;margin:0 auto;}
.aimlBenefitsWrap .titleWrap{margin:0 auto 30px;text-align:center;}
.aimlBenefitsWrap .titleWrap h3{color:#000000;font-size:2.2rem;font-weight:900;margin:0 auto;}
.aimlBenefitsWrap .itemWrap{padding:30px;background-color:#ffffff;border-radius:10px;margin:0 auto 16px;}
.aimlBenefitsWrap .itemWrap .iconWrap{text-align:center;position:relative;}
.aimlBenefitsWrap .itemWrap .iconWrap img{position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);}
.aimlBenefitsWrap .itemWrap .dataWrap{margin:20px auto 0;text-align:center;min-height:110px;}
.aimlBenefitsWrap .itemWrap .dataWrap h2{color:#000000;font-weight:600;font-size:2rem;line-height:28px;}
.aimlBenefitsWrap .itemWrap .dataWrap p{margin:0;color:#0F172A;font-size:1.6rem;line-height:26px;letter-spacing:0.1px;}
.benefitsWrapper .itemWrap .dataWrap{min-height:164px;}
.benefitsDigiWrap .itemWrap .dataWrap{min-height:120px;}
.aiMlInsdustWrap{width:100%;background-color:#ffffff;padding:60px 0;margin:0 auto;}
.aiMlInsdustWrap .dataWrap h2{color:#000000;font-size:3.2rem;font-weight:900;margin:0 0 25px;}
.aiMlInsdustWrap .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 0 15px;}
.aiMlInsdustWrap .dataWrap ul li{color:#0F172A;font-size:1.6rem;margin:0 0 16px;font-weight:300;letter-spacing:0.1px;}
.aiMlInsdustWrap .dataWrap ul li span{font-weight:500;display:inline-block;margin:0 4px 0 0;}

.aboutSectionWrap.paddingBtm20px{padding:80px 0 0;}
.techWrap{width:100%;padding:30px 0 80px;background-color:#ffffff;}
.techWrap .titleWrap{text-align:center;} 
.techWrap .titleWrap h2{color:#000000;font-size:3.2rem;font-weight:900;margin:0 0 25px;}
.techWrap .titleWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0;}
.techWrap .subTtlWrap{text-align:left;margin:20px 0 16px;}
.techWrap .subTtlWrap h2{color:#000000;font-size:2rem;font-weight:600;margin:0;}
.techWrap ul{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.techWrap ul li{padding:0 8px;}
.techWrap ul.topBtnlistWrap{justify-content:center;padding:0 0 30px;}
.techWrap ul.topBtnlistWrap li{padding:0 5px;}
.techWrap ul.topBtnlistWrap li .btn{display:inline-block;border:0;box-shadow:none;background-color:rgba(112, 26, 117, 0.1);color:#701A75;font-size:1.2rem;font-weight:600;letter-spacing:0.2px;margin:0;padding:4px 10px;border-radius:6px;line-height:16px;}
.techWrap ul.sortListWrap{justify-content:center;}
.techWrap .itemWrap{padding:0px;background-color:#ffffff;border-radius:10px;margin:0 auto 16px;}
.techWrap .itemWrap .iconWrap{text-align:center;position:relative;background-color:#ffffff;box-shadow:0 5px 30px #0000001c;border-radius:10px;padding:16px;width:90px;height:90px;display:flex;align-items:center;justify-content:center;}
.techWrap .itemWrap .dataWrap{margin:20px auto 0;text-align:center;min-height:110px;display:none;}
.techWrap .itemWrap .dataWrap h2{color:#000000;font-weight:600;font-size:2rem;line-height:28px;}
.techWrap .itemWrap .dataWrap p{margin:0;color:#0F172A;font-size:1.6rem;line-height:26px;letter-spacing:0.1px;}
.techWrap.techFilterWrap ul.topBtnlistWrap li{padding:0 5px 5px;}
.techWrap.techFilterWrap{padding:20px 0 80px;}
/*---------About Us---------*/
.aboutSectionWrap{width:100%;background-color:#fafafa;padding:80px 0;}
.aboutSectionWrap.padBtm{padding:80px 0 60px;}
.careersIntroWrap.aboutSectionWrap.padBtmLess{width:100%;background-color:#ffffff;padding:60px 0;}
.aboutSectionWrap .dataWrap{padding:10px 0 0;}
.aboutSectionWrap .dataWrap h2{color:#000000;font-size:3.2rem;font-weight:900;margin:0 0 25px;}
.aboutSectionWrap .dataWrap h2.mrtBtms{margin:0 0 6px;}
.aboutSectionWrap .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 0 15px;}
.aboutSectionWrap .dataWrap ul li{color:#0F172A;font-size:1.6rem;margin:0 0 16px;font-weight:300;letter-spacing:0.1px;}
.aboutSectionWrap .dataWrap ul li span{font-weight:500;display:inline-block;margin:0 4px 0 0;}

.aboutSectionWrap .timelineWrap ul{display:flex;flex-flow:column;padding:0 0 0 30px;}
.aboutSectionWrap .timelineWrap ul li{max-width:60%;width:100%;position:relative;padding:6px 0;}
.aboutSectionWrap .timelineWrap ul li:nth-child(1),
.aboutSectionWrap .timelineWrap ul li:nth-child(3){align-self:flex-start;}
.aboutSectionWrap .timelineWrap ul li:nth-child(2),
.aboutSectionWrap .timelineWrap ul li:nth-child(4){align-self:flex-end;}
.aboutSectionWrap .timelineWrap ul li:nth-child(1):after,
.aboutSectionWrap .timelineWrap ul li:nth-child(2):after,
.aboutSectionWrap .timelineWrap ul li:nth-child(3):after{content:"";height:110px;width:150px;border:1px dashed #000000;position:absolute;border-radius:20px;}
.aboutSectionWrap .timelineWrap ul li:nth-child(1):after{bottom:-82px;left:160px;border-right:0;}
.aboutSectionWrap .timelineWrap ul li:nth-child(2):after{bottom:-68px;right:160px;border-left:0;}
.aboutSectionWrap .timelineWrap ul li:nth-child(3):after{bottom:-70px;left:160px;border-right:0;}
.aboutSectionWrap .timelineWrap ul li .itemWrap h2{font-size:1.8rem;color:#701a75;font-weight:900;margin:0 auto 4px;text-align:center;} 
.aboutSectionWrap .timelineWrap ul li .itemWrap .dataWrap{border:1px solid #dddddd;border-radius:24px;padding:15px;background-color:#ffffff;position:relative;z-index:1;box-shadow:0px 4px 4px 0px rgba(0,0,0,0.1);} 
.aboutSectionWrap .timelineWrap ul li .itemWrap .dataWrap:after{content:"";width:50px;height:50px;background-color:rgb(251 191 36 / 6%);border-radius:100%;position:absolute;right:0px;bottom:0px;}
.aboutSectionWrap .timelineWrap ul li .itemWrap .dataWrap p{margin:0 0 6px;font-size:1.5rem;line-height:24px;letter-spacing:0.2px;}
.aboutSectionWrap .timelineWrap ul li .itemWrap .dataWrap h3{margin:0;font-size:1.6rem;font-weight:500;color:#000000;}
.aboutSectionWrap .timelineWrap ul li .itemWrap .dataWrap h3 span{font-weight:700;}

.ourJourneyLsitWrap{width:100%;padding:0 0 60px;margin:0 auto;}
.ourJourneyLsitWrap .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 0 15px;}
.ourJourneyLsitWrap .itemWrap{text-align:center;margin:0 auto 16px;padding:30px;min-height:200px;}
.ourJourneyLsitWrap .itemWrap.bgClr1{background-color:#BB6BD929;}
.ourJourneyLsitWrap .itemWrap.bgClr2{background-color:#2F80ED29;}
.ourJourneyLsitWrap .itemWrap.bgClr3{background-color:#27AE6029;}
.ourJourneyLsitWrap .itemWrap.bgClr4{background-color:#F1F5F9}
.ourJourneyLsitWrap .itemWrap p{color:#0F172A;margin:0 auto;font-size:1.5rem;line-height:28px;letter-spacing:0.2px;}
.ourClients{width:100%;background-color:#fafafa;padding:60px 0 160px;margin:0 auto -120px;}
.ourClients .titleWrap{margin:0 auto 20px;text-align:center;width:100%;}
.ourClients .titleWrap h2{color:#000000;font-size:3.2rem;font-weight:900;margin:0 auto;}
.ourClients ul{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;}
.ourClients ul li{/*width:15%;*/ width:12%;margin:0 0 20px;} 

.teamDataWrap{width:100%;padding:60px 0;margin:0 auto;background-color:#ffffff;}
.teamDataWrap .titleWrap{margin:0 auto 20px;text-align:center;width:100%;}
.teamDataWrap .titleWrap h2{color:#000000;font-size:3.2rem;font-weight:900;margin:0 auto;}
.teamDataWrap .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 0 15px;}
.teamDataWrap .itemWrap{width:100%;background-color:#F1F5F9;padding:0 30px 30px;position:relative;margin:70px auto 0;}
.teamDataWrap .itemWrap .imgWrap{width:240px;height:240px;border-radius:100%;margin:0 auto;overflow:hidden;position:absolute;top:-60px;left:50%;transform:translateX(-50%);border:1px solid #f3f3f3;}
.teamDataWrap .itemWrap .imgWrap img{width:100%;height:100%;object-fit:cover;}
.teamDataWrap .itemWrap .dataWrap{padding:200px 0 0;text-align:center;min-height:415px;}
.teamDataWrap .itemWrap .dataWrap h2{font-size:2rem;font-weight:600;}
.teamDataWrap .itemWrap .dataWrap h4{font-size:1.6rem;color:#555555;font-weight:300;}
.teamDataWrap .itemWrap .dataWrap p{font-size:1.5rem;margin:0 auto 4px;line-height:26px;}
.teamDataWrap .itemWrap .socialWrap{margin:30px auto 0;}
.teamDataWrap .itemWrap .socialWrap ul{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;}
.teamDataWrap .itemWrap .socialWrap li{margin:0 2px;}
.teamDataWrap .itemWrap .socialWrap ul li a{width:28px;height:28px;background-color:#fafafa;border-radius:100%;display:flex;align-items:center;justify-content:center;}
.teamDataWrap .itemWrap .socialWrap ul li:hover a svg path{fill:#ffffff;}
.teamDataWrap .itemWrap .socialWrap ul li:nth-child(1):hover a{background-color:#0A66C2;}
.teamDataWrap .itemWrap .socialWrap ul li:nth-child(2):hover a{background-color:#1DA1F2;}
.teamDataWrap .itemWrap .socialWrap ul li:nth-child(3):hover a{background-color:#0A66C2;}
.teamDataWrap .itemWrap .socialWrap ul li:nth-child(4):hover a{background-color:#fbad50;}
.teamDataWrap .dataWrap.martTop{margin:30px 0 0;}
.aboutSectionWrap.padBtmLess{padding:80px 0 40px;}
.vacanciesWrap{width:100%;padding:60px 0 180px;margin:0 auto -120px;background-color:#fafafa;}
.vacanciesWrap .titleWrap{margin:0 auto 20px;text-align:center;width:100%;}
.vacanciesWrap .titleWrap h2{color:#000000;font-size:3.2rem;font-weight:900;margin:0 auto;}
.vacanciesWrap .itemWrap{padding:20px;background-color:#F1F5F9;margin:0 auto 16px;}
.vacanciesWrap .itemWrap h2{font-size:2rem;font-weight:600;}
.vacanciesWrap .itemWrap span{font-size:1.5rem;color:#000000;}
.vacanciesWrap .itemWrap span strong{font-weight:500;}
.vacanciesWrap .itemWrap p{margin:0;color:#0F172A;font-size:1.5rem;line-height:26px;letter-spacing:0.1px;min-height:160px;}
.vacanciesWrap .itemWrap a{display:inline-block;color:#701A75;background-color:rgba(112, 26, 117, 0.1);font-size:1.2rem;font-weight:600;padding:7px 20px 10px;border-radius:30px;line-height:normal;letter-spacing:0.2px;margin:16px 0 0;}
.vacanciesWrap .itemWrap a:hover{color:#ffffff;background-color:#701A75;}
.visionWrap{padding:80px 0 0;}
.missionWrap{padding:0 0 80px;}
.missionWrap .dataWrap{text-align:center;}

.jobDetails{width:100%;margin:0 auto;padding:60px 0;background-color:#ffffff;}
.jobDetails h2{color:#000000;font-size:2.6rem;font-weight:900;margin:0 0 10px;}
.jobDetails p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 0 20px;}
.jobDetails h3{font-size:1.8rem;color:#000000;font-weight:600;margin:0 0 15px;}
.jobDetails ul{margin:0 0 20px;}
.jobDetails ul li{padding:0 0 0 12px;position:relative;color:#0F172A;font-size:1.6rem;font-weight:400;margin:0 0 14px;line-height:24px;}
.jobDetails ul li:before{content:"";width:5px;height:5px;background-color:#701A75;border-radius:100%;position:absolute;left:0px;top:10px;}
.jobDetails .ctaWrap{padding:10px 0 0;display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;}
.jobDetails .ctaWrap a{background-color:#701A75;color:#ffffff;display:inline-block;margin:0 18px 0 0;padding:6px 30px;border-radius:30px;font-size:1.2rem;font-weight:600;letter-spacing:0.2px;}
/*---------Case Studies---------*/
.caseStudyTopWrap{width:100%;margin:0 auto;padding:80px 0 40px;background-color:#ffffff;}
.caseStudyTopWrap .titleWrap{text-align:center;margin:0 auto 40px;}
.caseStudyTopWrap .titleWrap h2{margin:0 auto;color:#000000;font-size:2.2rem;font-weight:600;}
.caseStudyTopWrap .itemWrap{background-color:#F1F5F9;padding:30px;height:100%;text-align:center;}
.caseStudyTopWrap .itemWrap h2{color:#000000;font-weight:600;font-size:2rem;line-height:28px;margin:0 auto 15px;}
.caseStudyTopWrap .itemWrap p{margin:0 auto;color:#0F172A;font-size:1.5rem;line-height:26px;letter-spacing:0.1px;}
.csaeStudyListWrap{width:100%;margin:0 auto;padding:60px 0 0;background-color:#ffffff;}
.csaeStudyListWrap .pad0{padding:0;}
.csaeStudyListWrap .itemWrap{padding:30px;position:relative;height:100%;}
.csaeStudyListWrap .itemWrap:after,
.csaeStudyListWrap .itemWrap:before{content:"";background-color:#E4E4E7;height:100%;width:1px;position:absolute;right:0;top:0;}
.csaeStudyListWrap .itemWrap:before{width:100%;height:1px;bottom:0;left:0;top:auto;right:auto;}
.csaeStudyListWrap .itemWrap.noContAfter:after{content:none;}
.csaeStudyListWrap .itemWrap.noContBefore:before{background-color:#E4E4E7;height:100%;width:1px;left:0;top:0;}
.csaeStudyListWrap .itemWrap .icoWrap{text-align:center;margin:0 auto 10px;}
.csaeStudyListWrap .itemWrap .dataWrap{text-align:center;}
.csaeStudyListWrap .itemWrap .dataWrap h2{color:#000000;font-weight:600;font-size:2rem;line-height:28px;}
.csaeStudyListWrap .itemWrap .dataWrap p{margin:0 auto;color:#0F172A;font-size:1.5rem;line-height:26px;letter-spacing:0.1px;}
.caseStudyBtmWrap{width:100%;margin:0 auto;padding:60px 0 80px;background-color:#ffffff;}
.caseStudyBtmWrap .itemWrap{text-align:center;margin:0 auto 16px;padding:30px;min-height:200px;}
.caseStudyBtmWrap .itemWrap.bgClr1{background-color:#BB6BD929;}
.caseStudyBtmWrap .itemWrap.bgClr2{background-color:#2F80ED29;}
.caseStudyBtmWrap .itemWrap.bgClr3{background-color:#27AE6029;}
.caseStudyBtmWrap .itemWrap h2{color:#000000;font-weight:700;font-size:2rem;line-height:28px;}
.caseStudyBtmWrap .itemWrap p{color:#0F172A;margin:0 auto;font-size:1.5rem;line-height:26px;letter-spacing:0.1px;}
/*---------Masonry Structure---------*/
.sticyDiv{position:-webkit-sticky;position:sticky;top:140px;-webkit-transition:all .3s linear;-moz-transition:all .3s linear;-o-transition:all .3s linear;transition:all .3s linear;}
.masonaryWrap .itemWrap{box-shadow:0 0 30px #E4E4E7;border:1px solid #E4E4E7;padding:30px;margin:0 6px 12px;border-radius:10px;background-color:#ffffff;}
.masonaryWrap .itemWrap .icoWrap{margin:0 0 16px;}
.masonaryWrap .itemWrap .icoWrap img{width:100px;}
.masonaryWrap .itemWrap .dataWrap h2{color:#000000;font-weight:600;font-size:1.8rem;line-height:26px;}
.masonaryWrap .itemWrap .dataWrap p{margin:0 auto;color:#0F172A;font-size:1.5rem;line-height:26px;letter-spacing:0.1px;}
/*---------Digital Transformation---------*/
.digitalTrnsformWrap .tab-pane>.dataWrap{padding:0 16px;}
.digitalTrnsformWrap .dataWrap h2{color:#000000;font-size:3.2rem;font-weight:900;margin:30px 0 10px;}
.digitalTrnsformWrap .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 0 15px;}
.digitalTrnsformWrap .dataWrap h3{color:#000000;font-size:1.8rem;font-weight:700;}
.digitalTrnsformWrap .masonaryWrap{padding:20px 0;}
/*---------Security and Testing---------*/
.testPageTopWrap{width:100%;margin:0 auto;padding:60px 0;background-color:#fafafa;position:relative;z-index:9;}
.testPageTopWrap .itemWrap{background-color:#ffffff;padding:30px;text-align:center;border:1px solid #E4E4E7;border-radius:10px;}
.testPageTopWrap .itemWrap .icoWrap{text-align:center;position:relative;}
.testPageTopWrap .itemWrap .icoWrap img{position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);}
.testPageTopWrap .itemWrap .dataWrap h2{color:#000000;font-size:2rem;font-weight:700;margin:30px 0 10px;}
.testPageTopWrap .itemWrap .dataWrap p{min-height:260px;margin:0 auto;color:#0F172A;font-size:1.5rem;line-height:28px;letter-spacing:0.2px;}
.testPageTopWrap .dataWrap.martTop{padding:30px 0 0;margin:0 auto;text-align:center;}
.testPageTopWrap .dataWrap p{margin:0 auto;color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;}
.testingWrapper{width:100%;margin:0 auto;padding:50px 0 0;background-color:#ffffff;}
.testingWrapper .dataWrap{padding:0 0 30px;}
.testingWrapper .dataWrap h2{color:#000000;font-size:3.2rem;font-weight:900;margin:0 0 10px;}
.testingWrapper .dataWrap p{margin:0 auto 10px;color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;}
.testingWrapper .dataWrap h4{color:#000000;margin:25px 0 6px;font-size:2rem;font-weight:600;letter-spacing:0.2px;}
.testingValueWrap{width:100%;margin:20px auto 0;padding:30px 0;background-color:#dd9f00;}
.testingValueWrap .titleWrap{margin:0 auto 20px;}
.testingValueWrap .titleWrap h3{color:#ffffff;font-size:3rem;text-align:center;font-weight:900;margin:0 auto;}
.testingValueWrap .itmeWrap{text-align:center;position:relative;padding:20px 10px;}
.testingValueWrap .itmeWrap.afterContent:after{content:"";width:1px;height:100%;border-right:1px dashed rgba(255,255,255,0.5);position:absolute;right:0;top:0;}
.testingValueWrap .itmeWrap .noWrap h2{font-size:2.8rem;font-weight:900;color:#ffffff;margin:0 auto 10px;}
.testingValueWrap .itmeWrap .dataWrap p{margin:0 auto;color:#ffffff;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;}
.testingValueWrap ul{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start;text-align:left;padding:0 30px 0;}
.testingValueWrap ul li{width:48%;margin:0 0 10px;}
.testingValueWrap ul li .dataWrap{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;text-align:left;}
.testingValueWrap ul li h2{font-size:2.8rem;font-weight:900;color:#ffffff;margin:0 10px 0 0;}
.testingValueWrap ul li p{margin:0;color:#ffffff;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;text-align:left;}

.commponTabWrap{width:100%;padding:0 0 60px;margin:0 auto;background-color:#ffffff;}
.commponTabWrap .nav-tabs{position:relative;border:0;margin:0 auto;padding:0;height:200px;}
.commponTabWrap .nav-tabs:after{content:"";background-image:url(../img/curve.png);background-repeat:no-repeat;width:100%;height:183px;background-size:cover;background-position:center;position:absolute;top:-4px;left:50%;transform:translateX(-50%);}
.commponTabWrap .nav-tabs .nav-link{border:0;border-radius:0;outline:0;background-color:transparent;padding:50px 10px 0;position:relative;z-index:9;font-size:1.8rem;font-weight:600;letter-spacing:0.2px;color:#000000;}
.commponTabWrap .nav-tabs .nav-link.active, 
.commponTabWrap .nav-tabs .nav-item.show .nav-link{color:#dd9f00;}
.commponTabWrap .nav-tabs li{position:absolute;}
.commponTabWrap .nav-tabs li:nth-child(1){left:424px;bottom:-15px;}
.commponTabWrap .nav-tabs li:nth-child(2){right:210px;bottom:4px;}
.commponTabWrap .nav-tabs li .nav-link:after{content:"";background-repeat:no-repeat;background-size:cover;width:100px;height:100px;position:absolute;}
.commponTabWrap .nav-tabs li:nth-child(1) .nav-link:after{background-image:url(../img/functionalQA.png);top:-54px;left:10px;}
.commponTabWrap .nav-tabs li:nth-child(2) .nav-link:after{background-image:url(../img/AutomationTesting.png);top:-56px;left:56px;}
/*---------Projects---------*/
.ProjectSecWrap{width:100%;padding:60px 0 80px;}
.ProjectSecWrap .videoWrap{border-radius:4px;flex-direction:column;justify-content:center;display:flex;position:sticky;top:16vh;}
.ProjectSecWrap .videoWrap .video{position:relative;top:18px;left:-70px;}
.ProjectSecWrap .videoWrap .video video{width:calc(100% + 70px);}
.ProjectSecWrap .dataWrap{padding:10px 0 0 40px;}
.ProjectSecWrap .dataWrap h2{color:#000000;font-size:3.2rem;line-height:44px;font-weight:900;margin:0 0 25px;}
.ProjectSecWrap .dataWrap h4{color:#000000;font-size:2.4rem;font-weight:900;margin:25px 0 10px;}
.ProjectSecWrap .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 0 15px;}
.ProjectSecWrap .dataWrap ul{padding:30px 0 0;}
.ProjectSecWrap .dataWrap ul.padLess{padding:10px 0 0;}
.ProjectSecWrap .dataWrap ul li{padding:0 0 30px;}
.ProjectSecWrap .dataWrap ul li .dataWrap{padding:0;}
.ProjectSecWrap .dataWrap ul li .dataWrap h2{color:#000000;font-size:2.2rem;font-weight:900;margin:0 0 12px;}
.ProjectSecWrap .dataWrap ul li .dataWrap ul{padding:0;}
.ProjectSecWrap .dataWrap ul li .dataWrap ul li{padding:0;color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 0 18px;position:relative;padding:0 0 0 12px;}
.ProjectSecWrap .dataWrap ul li .dataWrap ul li:before{content:"";width:5px;height:5px;background-color:#701A75;border-radius:100%;position:absolute;left:0px;top:14px;}
.zohoCrmIntroWrap{padding:0 0 80px;}
.ProjectSecWrap .productImgSlider{background-color:#ffffff;padding:10px;box-shadow:0px 6px 6px 0px rgba(0,0,0,0.1);}
.ProjectSecWrap .productImgSlider .slick-arrow{position:absolute;bottom:-62px;background-color:#701A75!important;color:transparent;width:24px;height:24px;border-radius:100%;display:flex!important;align-items:center;justify-content:center;flex-flow:row wrap;}
.ProjectSecWrap .productImgSlider .slick-prev{left:60px;}
.ProjectSecWrap .productImgSlider .slick-next{right:96px;}
.ProjectSecWrap .productImgSlider .slick-arrow:before{content:"";background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='512' height='512' x='0' y='0' viewBox='0 0 492 492' style='enable-background:new 0 0 512 512' xml:space='preserve'><g><path d='M198.608 246.104 382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z' fill='%23ffffff' opacity='1' data-original='%23ffffff'></path></g></svg>");width:12px;height:12px;position:absolute;background-size:cover;}
.ProjectSecWrap .productImgSlider .slick-arrow.slick-prev{left:-24px;}
.ProjectSecWrap .productImgSlider .slick-arrow.slick-next{right:-24px;}
.ProjectSecWrap .productImgSlider .slick-arrow.slick-next:before{transform:rotate(-180deg);}
/*---------Bottom Pages---------*/
.commonPagesDataWrap{width:100%;padding:60px 0;margin:0 auto;background-color:#ffffff;}
.commonPagesDataWrap .dataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 0 15px;}
.commonPagesDataWrap .dataWrap h3{color:#000000;font-size:2.4rem;font-weight:900;margin:25px 0 10px;}
.commonPagesDataWrap .dataWrap h4{color:#000000;font-size:1.8rem;font-weight:900;margin:25px 0 10px;}
.commonPagesDataWrap .dataWrap ul li{padding:0;color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 0 18px;position:relative;padding:0 0 0 12px;}
.commonPagesDataWrap .dataWrap ul li:before{content:"";width:5px;height:5px;background-color:#701A75;border-radius:100%;position:absolute;left:0px;top:14px;}
/*---------Startups---------*/
.startUpData{width:100%;padding:60px 0;margin:0 auto;background-color:#fafafa;position:relative;z-index:9;}
.startUpData .topDataWrap{text-align:center;}
.startUpData .topDataWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 auto;}
.crmServListWrap{width:100%;margin:0 auto;background-color:#ffffff;padding:80px 0 0;}
.crmServListWrap .itemWrap{margin:0 0 50px;padding:0 20px;}
.crmServListWrap .itemWrap .icoWrap{width:34px;margin:0;}
.crmServListWrap .itemWrap .icoWrap img{width:100%;}
.crmServListWrap .itemWrap .dataWrap{margin:18px 0 0;padding:0;}
.crmServListWrap .itemWrap .dataWrap h2{color:#000000;font-weight:600;font-size:2rem;line-height:28px;}
.crmServListWrap .itemWrap .dataWrap ul{margin:10px 0 0;}
.crmServListWrap .itemWrap .dataWrap ul li{color:#0F172A;margin:0 auto 10px;font-size:1.5rem;line-height:26px;letter-spacing:0.1px;}
.startupTabWrap{width:100%;margin:0 auto;background-color:#ffffff;}
.startupTabWrap .titleWrap{text-align:center;margin:0 auto;padding:12px 0 0;}
.startupTabWrap .titleWrap h2{color:#000000;font-size:2.2rem;font-weight:600;margin:0 auto;}
.startupTabWrap.commponTabWrap .nav-tabs{margin:-36px auto 0;height:240px;}
.startupTabWrap.commponTabWrap .nav-tabs .nav-link{max-width:250px;} 
.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(1){left:378px;bottom:-15px;}
.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(2){right:188px;bottom:4px;}
.startupTabWrap.commponTabWrap .nav-tabs li .nav-link:after{content:"";background-repeat:no-repeat;background-size:cover;width:100px;height:100px;position:absolute;}
.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(1) .nav-link:after{background-image:url(../img/functionalQA.png);top:-58px;left:60px;}
.startupTabWrap.commponTabWrap .nav-tabs li:nth-child(2) .nav-link:after{background-image:url(../img/AutomationTesting.png);top:-64px;left:90px;}
/*---------Contact Us---------*/
.contactWrap{width:100%;padding:80px 0;margin:0 auto;background-color:#fafafa;}
.contactWrap .titleWrap{text-align:left;margin:0 auto;}
.contactWrap .titleWrap h2{color:#701A75;font-weight:800;font-size:2.4rem;line-height:28px;margin:0;}
.contactWrap .dataWrap{padding:0 40px 0 0;}
.contactWrap .dataWrap p{font-size:1.6rem;line-height:34px;margin:0;letter-spacing:0.1px;color:#2b2b2b;font-weight:400;}
ul.contactAdrress{display:flex;align-items:center;flex-flow:row wrap;justify-content:space-between;padding:20px 0;}
ul.contactAdrress li{display:flex;align-items:flex-start;flex-flow:row wrap;justify-content:space-between;width:100%;margin:0 0 15px;}
ul.contactAdrress li .iconWrap{width:30px;margin:0;}
ul.contactAdrress li .dataWrap{width:calc(100% - 30px);}
ul.contactAdrress li .dataWrap h2{font-size:2.2rem;font-weight:700;text-align:center;letter-spacing:0.2px;}
ul.contactAdrress li .dataWrap p{margin:0;font-size:1.5rem;color:#666666;text-align:left;letter-spacing:0.2px;line-height:26px;font-weight:500;}
ul.socialConnect{display:flex;flex-flow:row wrap;justify-content:flex-start;padding:15px 0 0;align-items:center;}
ul.socialConnect li{margin:0 15px 0 0;}
ul.socialConnect li a{display:flex;width:40px;height:40px;border-radius:100%;background-color:#eeeeee;-webkit-transition:all ease .5s;transition:all ease .5s;flex-flow:row wrap;justify-content:center;align-items:center;color:#000000;}
ul.socialConnect li:hover a{background-color:#701A75;-webkit-transform:translateY(-5px);transform:translateY(-5px);}
ul.socialConnect li:hover a svg path{fill:#ffffff;}
.contactWrap .formWrap{background-color:#ffffff;border-radius:10px;padding:50px 30px;position:relative;box-shadow:0px 4px 4px 0px rgba(0,0,0,0.2);}
.contactWrap .formWrap:after{content:'';position:absolute;bottom:0;left:0;right:0;height:145px;background:rgb(112 26 117 / 4%);border-radius:50% 0 10px 10px;}
.contactWrap ul.formList{position:relative;z-index:9;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.contactWrap ul.formList li.half{width:49%;}
.contactWrap ul.formList li.full{width:100%;}
.contactWrap ul.formList .form-field{position:relative;margin: 0 0 15px;}
.contactWrap ul.formList .form-field .form-input{height:48px;background:#ffffff;color:#000000;font-size:1.4rem;border-radius:40px;-webkit-box-shadow:none;box-shadow:none;border:1px solid rgba(0, 0, 0, 0.13);padding:0 20px;position:relative;width:100%;}
.contactWrap ul.formList .form-field.textareaHt .form-input{height:120px;padding:20px;}
.contactWrap ul.formList li .btn{border:0;outline:0;box-shadow:0 0.5rem 1.125rem -0.5rem rgb(36 103 137 / 51%);background-color:#701A75;color:#ffffff;padding:10px 36px;text-transform:uppercase;font-size:1.4rem;font-weight:500;letter-spacing:2px;border-radius:30px;width:100%;transition:color 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;}
.contactWrap ul.formList li .btn:hover{background-color:#701A75;box-shadow:none;}
.form-input::-webkit-input-placeholder{color:#888888;font-weight:200;font-size:1.2rem;letter-spacing:0.2px;}
.form-input::-moz-placeholder{color:#888888;font-weight:200;font-size:1.2rem;letter-spacing:0.2px;}
.form-input:-ms-input-placeholder{color:#888888;font-weight:200;font-size:1.2rem;letter-spacing:0.2px;}
.form-input:-moz-placeholder{color:#888888;font-weight:200;font-size:1.2rem;letter-spacing:0.2px;}
.contactWrap ul.formList li .uploadFile{height:48px;background:#ffffff;color:#000000;font-size:1.4rem;border-radius:40px;-webkit-box-shadow:none;box-shadow:none;border:1px solid rgba(0, 0, 0, 0.13);padding: 10px 10px 4px 10px;position:relative;width:100%;overflow:hidden;resize:none;}
.contactWrap ul.formList li .uploadFile span{color:#888888;font-weight:200;font-size:1.2rem;letter-spacing:0.2px;}
.contactWrap ul.formList li .uploadFile [type=file]{cursor:pointer!important;display:block;font-size:999px;filter:alpha(opacity=0);min-height:100%;min-width:100%;opacity:0;position:absolute;right:0px;text-align:right;top:0px;z-index:1;}
/*---------not any page yet---------*/
.howWeWrap{width:100%;margin:0 auto;background-color:#F1F5F9;padding:60px 0;}
.howWeWrap .dataWrap h2{color:#000000;font-size:3.2rem;font-weight:900;margin:0 0 25px;}
.howWeWrap .dataWrap p{color:#0F172A;margin:0 0 15px;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;}
.howWeWrap .dataWrap h3{margin:25px 0 10px;font-size:1.9rem;font-weight:600;}
.howWeWrap .dataWrap ul{margin:0;}
.howWeWrap .dataWrap ul li{position:relative;color:#0F172A;font-size:1.4rem;font-weight:500;margin:0 0 10px;padding:0 0 0 18px;}
.howWeWrap .dataWrap ul li:before{content:"👉";position:absolute;left:0;top:0;font-size:1.4rem;}
.howWeWrap .padLt{padding-left:60px;}
.howWeWrap .topService{position:relative;display:flex;flex-flow:row wrap;align-items:flex-end;justify-content:space-between;}
.howWeWrap .topService:before{content:"";background-image:url(../img/Blob.png);background-repeat:no-repeat;width:620px;height:620px;position:absolute;top:0;left:43%;background-size:contain;transform:translateX(-50%);}
.howWeWrap .topService:after{content:"";background-image:url(../img/Icons.png);background-repeat:no-repeat;width:400px;height:510px;position:absolute;top:-10px;left:70%;transform:translateX(-50%);background-size:contain;}
.howWeWrap .topService .topLeft{position:relative;z-index:9;background-color:#ffffff;border-radius:8px;box-shadow:0px 8px 8px 0px rgba(0,0,0,0.16);padding:13px;width:40%;margin:0 0 16px;}
.howWeWrap .topService .topLeft span{color:#1E40AF;display:inline-block;background-color:#DBEAFE;font-size:1.2rem;font-weight:500;padding:7px 13px;line-height:12px;border-radius:6px;margin:0 0 10px;letter-spacing:0.2px;}
.howWeWrap .topService .topLeft h4{color:#0F172A;font-size:1.7rem;font-weight:500;line-height:22px;margin:0 0 4px;}
.howWeWrap .topService .topLeft p{color:#475569;font-size:1.5rem;line-height:26px;}
.howWeWrap .topService .topLeft a{color:#2563EB;border-radius:8px;display:inline-block;border:2px solid #2563EB;width:100%;text-align:center;padding:4px 10px;font-size:1.4rem;font-weight:500;margin:10px 0 0;}
.howWeWrap .topService .topRight{position:relative;z-index:9;width:calc(100% - 41.60%);}
.howWeWrap .topService .topRight>img{margin:0 0 -31px 0;width:260px;}
.howWeWrap .topService .topRight .posImg{position:absolute;bottom:-8px;right:40px;}
.howWeWrap .bottomService{display:flex;flex-flow:row wrap;align-items:flex-start;justify-content:center;position:relative;z-index:9;}
.howWeWrap .bottomService .leftImg{width:62%;margin:0 0 0 -26px;} 
.howWeWrap .bottomService .rightImg{width:46%;margin:0 0 0 -34px;}
/*---------Footer---------*/
.footerWrap{width:100%;margin:0 auto;padding:100px 0 0;background-color:#e9e9e9;position:relative;}
.footerWrap .logoWrap{width:100px;}
.footerWrap .footerInfoWrap{margin:18px 0;}
.footerWrap .footerInfoWrap p{color:#0F172A;font-size:1.4rem;font-weight:500;letter-spacing:0.4px;margin:0;line-height:28px;}
.footerMenu{margin:0 auto;padding:0 40px;}
.footerMenu h2,
.newsLetterWrap h2{color:#000000;font-size:1.4rem;font-weight:600;letter-spacing:0.2px;margin:0 0 20px;text-transform:capitalize;}
.footerMenu ul li{margin:0 0 8px;} 
.footerMenu ul li a{color:#000000;font-size:1.4rem;text-transform:capitalize;font-weight:400;letter-spacing:0.2px;}
.footerMenu ul li:hover a{color:#701A75;} 
/*---------Copyright---------*/
.copyrightWrap{width:100%;margin:20px auto 0;padding:16px 0;background-color:#e9e9e9;border-top:1px solid #0000001A;}
.copyrightWrap .dataWrap p{margin:0;color:#000000;font-size:1.3rem;font-weight:400;letter-spacing:0.2px;}
.copyrightWrap ul{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;}
.copyrightWrap ul li{margin:0 0 0 20px;}
.copyrightWrap ul li a{color:#000000;font-size:1.3rem;font-weight:400;}
.copyrightWrap ul li:hover a{color:#701A75;}
.copyrightWrap .locationWrap{text-align:center;display:flex;align-items:center;justify-content:flex-end;flex-flow:row wrap;}
.copyrightWrap .locationWrap p{margin:0;font-weight:600;color:#000000;}
.copyrightWrap .locationWrap ul{margin:0;}
.copyrightWrap .locationWrap ul li:nth-child(1){margin:0 10px 0 6px!important;}
.copyrightWrap .locationWrap ul li{font-size:1.3rem;color:#000000;font-weight:400;margin:0 10px 0 0!important;padding:0 10px 0 0;position:relative;line-height:16px;}
.copyrightWrap .locationWrap ul li:after{content:"";width:1px;height:100%;background-color:#701A75;position:absolute;right:0;top:0;}
.copyrightWrap .locationWrap ul li:nth-child(5):after{content:none;}

.mvpWrap{width:100%;margin:0 auto;padding:0 0 60px;position:relative;background-color:#ffffff;}
.mvpWrap .mvpBoxWrap{width:100%;background-color:#f8f8f8;box-shadow:0 0 30px rgba(0,0,0,0.12);position:relative;z-index:9;margin:-60px auto 0;padding:30px 20px 40px 0;border-radius:0px;background-image:linear-gradient(-181deg, #ffffff, #F4F1F4);}
.mvpWrap .leftDataWrap{padding:0;text-align:center;margin:0 auto;}
.mvpWrap .leftDataWrap h2{color:#000000;font-size:4.2rem;font-weight:800;margin:0 0 8px;}
.mvpWrap .leftDataWrap h4{font-size:1.4rem;font-weight:500;color:#9e9e9e;line-height:26px;letter-spacing:0.8px;margin:0 auto;text-transform:uppercase;}
.mvpWrap .leftDataWrap p{margin:0;color:#000000;font-size:1.6rem;line-height:28px;letter-spacing:0.2px;font-weight:400;}
.mvpWrap .rightDataWrap{position:relative;z-index:9;margin:0 auto;padding:0 30px 30px;min-height:436px;}
.mvpWrap .rightDataWrap .imgWrap{padding:30px 0 0;margin:0 auto;text-align:center;z-index:-1;position:relative;}
.mvpWrap .rightDataWrap ul{display:flex;flex-flow:row wrap;justify-content:center;align-items:flex-start;max-width:970px;}
.mvpWrap .rightDataWrap ul li{width:16.66%;position:relative;}
.mvpWrap .rightDataWrap ul li .itemWrap .icoWrap{position:absolute;display:flex;flex-flow:row wrap;align-items:center;justify-content:center;width:120px;height:120px;padding:0px;top:-168px;border-radius:100%;background-color:#FAF6F6;box-shadow:0px 4px 2px rgba(0,0,0,0.12);}
.mvpWrap .rightDataWrap ul li:nth-child(1) .itemWrap .icoWrap{left:22px;}
.mvpWrap .rightDataWrap ul li:nth-child(2) .itemWrap .icoWrap{left:22px;top:-164px;}
.mvpWrap .rightDataWrap ul li:nth-child(3) .itemWrap .icoWrap{left:22px;top:-167px;}
.mvpWrap .rightDataWrap ul li:nth-child(4) .itemWrap .icoWrap{left:24px;top:-162px;}
.mvpWrap .rightDataWrap ul li:nth-child(5) .itemWrap .icoWrap{left:22px;top:-164px;}
.mvpWrap .rightDataWrap ul li:nth-child(6) .itemWrap .icoWrap{left:20px;top:-160px;}
.mvpWrap .rightDataWrap ul li .itemWrap .icoWrap:after{content:"";width:130px;height:130px;background-color:#ffffff;border-radius:100%;position:absolute;top:-5px;z-index:-1;}
.mvpWrap .rightDataWrap ul li:nth-child(1) .itemWrap .icoWrap:after{left:-5px;}
.mvpWrap .rightDataWrap ul li:nth-child(4) .itemWrap .icoWrap:after{left:-5px;}
.mvpWrap .rightDataWrap ul li:hover .itemWrap .icoWrap{box-shadow:0px 8px 8px rgba(0,0,0,0.12);}
.mvpWrap .rightDataWrap ul li:nth-child(1):hover .itemWrap .icoWrap{background-color:#85BD34;}
.mvpWrap .rightDataWrap ul li:nth-child(2):hover .itemWrap .icoWrap{background-color:#68C07B;}
.mvpWrap .rightDataWrap ul li:nth-child(3):hover .itemWrap .icoWrap{background-color:#269ABB;}
.mvpWrap .rightDataWrap ul li:nth-child(4):hover .itemWrap .icoWrap{background-color:#3758D1;}
.mvpWrap .rightDataWrap ul li:nth-child(5):hover .itemWrap .icoWrap{background-color:#3758D1;}
.mvpWrap .rightDataWrap ul li:nth-child(6):hover .itemWrap .icoWrap{background-color:#7B277D;}

.mvpWrap .rightDataWrap ul li .itemWrap .icoWrap img{width:50px;height:50px;object-fit:contain;}
.mvpWrap .rightDataWrap ul li:hover .itemWrap .icoWrap .imgNoHover{display:none;}
.mvpWrap .rightDataWrap ul li .itemWrap .icoWrap .imgHover{display:none;}
.mvpWrap .rightDataWrap ul li:hover .itemWrap .icoWrap .imgHover{display:inline-block;}
.mvpWrap .rightDataWrap ul li .itemWrap .dataWrap{position:absolute;left:5px;text-align:center;width:148px;}
.mvpWrap .rightDataWrap ul li:nth-child(1) .itemWrap .dataWrap,
.mvpWrap .rightDataWrap ul li:nth-child(3) .itemWrap .dataWrap,
.mvpWrap .rightDataWrap ul li:nth-child(5) .itemWrap .dataWrap{top:34px;}
.mvpWrap .rightDataWrap ul li:nth-child(2) .itemWrap .dataWrap,
.mvpWrap .rightDataWrap ul li:nth-child(4) .itemWrap .dataWrap,
.mvpWrap .rightDataWrap ul li:nth-child(6) .itemWrap .dataWrap{top:8px;}
.mvpWrap .rightDataWrap ul li .itemWrap .dataWrap:after{content:"";width:3px;height:100px;position:absolute;z-index:-11;top:-84px;left:73px;}
.mvpWrap .rightDataWrap ul li:nth-child(1) .itemWrap .dataWrap:after{background-color:#85BD34;}
.mvpWrap .rightDataWrap ul li:nth-child(2) .itemWrap .dataWrap:after{background-color:#68C07B;}
.mvpWrap .rightDataWrap ul li:nth-child(3) .itemWrap .dataWrap:after{background-color:#269ABB;}
.mvpWrap .rightDataWrap ul li:nth-child(4) .itemWrap .dataWrap:after{background-color:#3758D1;}
.mvpWrap .rightDataWrap ul li:nth-child(5) .itemWrap .dataWrap:after{background-color:#3758D1;}
.mvpWrap .rightDataWrap ul li:nth-child(6) .itemWrap .dataWrap:after{background-color:#7B277D;}
.mvpWrap .rightDataWrap ul li .itemWrap .dataWrap h3{width:32px;height:32px;border-radius:100%;margin:0 auto 6px;color:#ffffff;display:flex;align-items:center;justify-content:center;font-weight:700;font-size:1.3rem;}
.mvpWrap .rightDataWrap ul li:nth-child(1) .itemWrap .dataWrap h3{background-color:#85BD34;}
.mvpWrap .rightDataWrap ul li:nth-child(2) .itemWrap .dataWrap h3{background-color:#68C07B;}
.mvpWrap .rightDataWrap ul li:nth-child(3) .itemWrap .dataWrap h3{background-color:#269ABB;}
.mvpWrap .rightDataWrap ul li:nth-child(4) .itemWrap .dataWrap h3{background-color:#3758D1;}
.mvpWrap .rightDataWrap ul li:nth-child(5) .itemWrap .dataWrap h3{background-color:#3758D1;}
.mvpWrap .rightDataWrap ul li:nth-child(6) .itemWrap .dataWrap h3{background-color:#7B277D;}
.mvpWrap .rightDataWrap ul li .itemWrap .dataWrap h2{text-align:center;font-size:1.3rem;text-transform:uppercase;font-weight:700;letter-spacing:0.4px;}
.mvpWrap .rightDataWrap ul li:nth-child(1) .itemWrap .dataWrap h2{color:#85BD34;}
.mvpWrap .rightDataWrap ul li:nth-child(2) .itemWrap .dataWrap h2{color:#68C07B;}
.mvpWrap .rightDataWrap ul li:nth-child(3) .itemWrap .dataWrap h2{color:#269ABB;}
.mvpWrap .rightDataWrap ul li:nth-child(4) .itemWrap .dataWrap h2{color:#3758D1;}
.mvpWrap .rightDataWrap ul li:nth-child(5) .itemWrap .dataWrap h2{color:#3758D1;}
.mvpWrap .rightDataWrap ul li:nth-child(6) .itemWrap .dataWrap h2{color:#7B277D;}
.mvpWrap .rightDataWrap ul li .itemWrap .dataWrap p{color:#9B9B9B;margin:0 auto;font-size:1.3rem;line-height:18px;}
.mvpWrap .mvpBoxWrap .ctaWrap{text-align:center;}
.mvpWrap .mvpBoxWrap .ctaWrap .btn{background-color:#ffffff;color:#701a75;font-size:1.2rem;font-weight:700;letter-spacing:0.3px;outline:0;border:1px solid #701a75;box-shadow:none;padding:8px 16px;border-radius:30px;}
.mvpWrap .mvpBoxWrap .ctaWrap .btn:hover{background-color:#701a75;color:#ffffff;}

.reachOutWrap{margin:0 auto -65px;position:relative;z-index:1;}
.reachOutWrap .dataWrap{background-color:#dd9f00;padding:30px 100px 32px;text-align:center;border-radius:20px;background-image:url(../img/newletterBg.jpg);background-size:cover;background-repeat:no-repeat;background-position:center;}
.reachOutWrap .dataWrap h2{font-size:2.4rem;font-weight:600;line-height:34px;color:#000000;margin:0 auto;}
.reachOutWrap .dataWrap h3{font-size:2rem;font-weight:600;line-height:32px;}
.reachOutWrap .dataWrap p{font-size:1.6rem;font-weight:400;line-height:24px;margin:4px 0 6px;}
.reachOutWrap .dataWrap .btn{background-color:#000000;margin:16px auto 0;border-radius:30px;padding:10px 30px;font-size:1.2rem;text-transform:capitalize;font-weight:800;letter-spacing:0.2px;border:0;outline:0;box-shadow:none;color:#ffffff;}

.contactUsPopup.modal.show .modal-dialog{z-index:9;height:100vh;display:flex;flex-flow:row wrap;align-items:center;justify-content:center;}
.contactUsPopup .modal-content{border:0;border-radius:0px;}
.contactUsPopup .modal-header{border:0;padding:0;}
.contactUsPopup .modal-header .btn-close{position: absolute;width:20px;height:20px;padding:5px;opacity:1;top:5px;right:5px;border-radius:0 0 0 10px;background-color:#701a75;--bs-btn-close-bg:none;}
.contactUsPopup .modal-header .btn-close:after{content:"";background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='14' height='14' x='0' y='0' viewBox='0 0 320.591 320.591'><g><path d='M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z' fill='%23ffffff' opacity='1' data-original='%23ffffff'></path><path d='M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z' fill='%23ffffff' opacity='1' data-original='%23ffffff'></path></g></svg>");width:10px;height:10px;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);background-size:contain;background-position:center;background-repeat:no-repeat;z-index:99;}
.contactUsPopup .modal-body{padding:30px 30px 40px;}
.contactUsPopup .modal-body .titleWrap{margin:0 0 25px;}
.contactUsPopup .modal-body .titleWrap h2{font-size:2.4rem;font-weight:800;margin:0 0 2px;}
.contactUsPopup .modal-body .titleWrap p{font-size:1.6rem;color:#666666;letter-spacing:0.1px;font-weight:300;margin:0;}
.contactUsPopup .modal-body ul.formList{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.contactUsPopup .modal-body ul.formList li{width:48%;margin:0 0 16px;}
.contactUsPopup .modal-body ul.formList li.full{width:100%;}
.contactUsPopup .modal-body ul.formList li:last-child{margin:0;}
.contactUsPopup .modal-body ul.formList li .inputField label{width:100%;color:#000000;font-size:1.3rem;font-weight:600;letter-spacing:0.2px;line-height:16px;margin:0;}
.contactUsPopup .modal-body ul.formList li .inputField input,
.contactUsPopup .modal-body ul.formList li .selectField select,
.contactUsPopup .modal-body ul.formList li .inputField textarea{width:100%;height:42px;border:1px solid #dddddd;border-radius:6px;padding:2px 10px;font-weight:500;font-size:1.3rem;color:#000000;}
.contactUsPopup .modal-body ul.formList li .selectField{position:relative;}
.contactUsPopup .modal-body ul.formList li .selectField:after{content:"";background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='512' height='512' x='0' y='0' viewBox='0 0 492 492' style='enable-background:new 0 0 512 512' xml:space='preserve'><g><path d='M198.608 246.104 382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z' fill='%23999999' opacity='1' data-original='%23999999'></path></g></svg>");transform:rotate(-90deg);background-repeat:no-repeat;background-size:contain;width:10px;height:10px;position:absolute;right:8px;top:42px;}
.contactUsPopup .modal-body ul.formList li .selectField select{appearance:none;}
.contactUsPopup .modal-body ul.formList li .inputField textarea{height:80px;resize:none;}
.contactUsPopup .modal-body .file-upload-wrapper{position:relative;width:100%;height:42px;border:1px solid #dddddd;border-radius:6px;}
.contactUsPopup .modal-body .file-upload-wrapper:after{content:attr(data-text);font-size:1.4rem;position:absolute;top:0;left:0;background:#ffffff;padding:6px 15px;display:block;width:calc(100% - 40px);pointer-events:none;z-index:20;height:100%;color:#000000;border-radius:5px 10px 10px 5px;font-weight:400;text-overflow:ellipsis;width:calc(100% - 78px);white-space:nowrap;overflow:hidden;}
.contactUsPopup .modal-body .file-upload-wrapper:before{content:"Upload";position:absolute;top:0;right:0;display:inline-block;height:42px;background:#701a75;color:#ffffff;font-weight:600;z-index:25;font-size:1.4rem;line-height:42px;padding:0 15px;text-transform:capitalize;pointer-events:none;border-radius:0 5px 5px 0;}
.contactUsPopup .modal-body .file-upload-wrapper:hover:before{background:#701a75;}
.contactUsPopup .modal-body .file-upload-wrapper input{opacity:0;position:absolute;top:0;right:0;bottom:0;left:0;z-index:99;height:40px;margin:0;padding:0;display:block;cursor:pointer;width:100%;}
.contactUsPopup .modal-body .btnSubmti{outline:0;border:0;background-color:#701a75;box-shadow:none;border-radius:30px;padding:8px 40px;color:#ffffff;font-weight:500;letter-spacing:0.4px;}
.thankYouPopup{text-align:center;padding:25px 0 0;}
.thankYouPopup h3{font-size:2.4rem;font-weight:700;color:#000000;margin:0 auto 10px;}
.thankYouPopup p{margin:0 auto;font-size:1.8rem;font-weight:200;color:#666666;letter-spacing:0.2px;}

.productWrap #slider{position:relative;width:50%;height:360px;margin:0 auto;perspective:1400px;transform-style:preserve-3d;border-radius:12px;}
.productWrap input[type=radio]{display:none;}
.productWrap #slider label{position:absolute;width:100%;height:100%;left:0;top:0;border-radius:12px;transition: transform 0.6s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);}

#s1:checked ~ #slide1, 
#s2:checked ~ #slide2, 
#s3:checked ~ #slide3, 
#s4:checked ~ #slide4, 
#s5:checked ~ #slide5{box-shadow:0 10px 20px rgba(0,0,0, 0.1), 0 12px 6px rgba(0,0,0, 0.1);transform:translate3d(0%, 0, 0px);}

#s1:checked ~ #slide2, 
#s2:checked ~ #slide3, 
#s3:checked ~ #slide4, 
#s4:checked ~ #slide5, 
#s5:checked ~ #slide1{box-shadow:0 6px 10px rgba(0,0,0, 0.1), 0 2px 2px rgba(0,0,0, 0.1);transform:translate3d(20%, 0, -100px);}

#s1:checked ~ #slide3,
#s2:checked ~ #slide4,
#s3:checked ~ #slide5,
#s4:checked ~ #slide1,
#s5:checked ~ #slide2{box-shadow:0 1px 4px rgba(0,0,0, 0.1);transform:translate3d(40%, 0, -250px);}

#s1:checked ~ #slide4,
#s2:checked ~ #slide5,
#s3:checked ~ #slide1,
#s4:checked ~ #slide2,
#s5:checked ~ #slide3{box-shadow:0 1px 4px rgba(0,0,0, 0.1);transform:translate3d(-40%, 0, -250px);}

#s1:checked ~ #slide5,
#s2:checked ~ #slide1,
#s3:checked ~ #slide2,
#s4:checked ~ #slide3,
#s5:checked ~ #slide4{box-shadow:0 6px 10px rgba(0,0,0, 0.1), 0 2px 2px rgba(0,0,0, 0.1);transform:translate3d(-20%, 0, -100px);}

.productWrap .productSlde.productSlde1 .zoom-container{background-image:url(../img/productslider-1.png);}
.productWrap .productSlde.productSlde2 .zoom-container{background-image:url(../img/productslider-2.png);}
.productWrap .productSlde.productSlde3 .zoom-container{background-image:url(../img/productslider-5.png);}
.productWrap .productSlde.productSlde4 .zoom-container{background-image:url(../img/productslider-3.png);}
.productWrap .productSlde.productSlde5 .zoom-container{background-image:url(../img/productslider-6.png);}

.productWrap .productSlde .zoom-container{overflow:hidden;background-repeat:no-repeat;background-size:100% 100%;background-position:center;display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;height:100%;}
.productWrap .productSlde .zoom-container .dataWrap{width:calc(100% - 50%);padding:0 10px 0 30px;}
.productWrap .productSlde .zoom-container .dataWrap h2{font-size:2.6rem;color:#ffffff;font-weight:800;line-height:36px;}
.productWrap .productSlde .zoom-container .dataWrap p{font-size:1.5rem;color:#ffffff;margin:0 0 20px;}
.productWrap .productSlde .zoom-container .imgWrap{width:50%;padding:0 30px 0 0;}
.productWrap .productSlde.productSlde6 .zoom-container .dataWrap{width:60%;}
.productWrap .productSlde .zoom-container .dataWrap a{background-color:#ffffff;border-radius:30px;box-shadow:0px 6px 6px 0px rgba(0,0,0,0.1);padding:6px 20px;}
.productWrap .productSlde.productSlde6 .zoom-container .dataWrap a{text-transform:uppercase;color:#FFD600;font-size:1rem;font-weight:800;padding:8px 20px;}
.productWrap .productSlde.productSlde1 .zoom-container .dataWrap{width:100%;text-align:center;padding:0 30px;}

.pricingPageWrap{width:100%;margin:0 auto;padding:60px 0;}
.pricingPageWrap .titleWrap{text-align:center;padding:0 0 60px;}
.pricingPageWrap .titleWrap h2{margin:0 auto 10px;color:#000000;font-size:2.4rem;font-weight:500;}
.pricingPageWrap .titleWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 auto;}
.pricingPageWrap .pricingWrap ul.nav-tabs{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;border:0;margin:0 auto;}
.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item{padding:0 40px;position:relative;z-index:9;}
.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item:before{content:"";background-color:#dddddd;height:1px;width:100%;position:absolute;right:0px;top:50%;transform:translateY(-50%);z-index:-1;}
.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item:nth-child(1):before{width:calc(100% - 40px);}
.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item:nth-child(5):before{width:calc(100% - 40px);left:0;right:auto;}
.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item .nav-link{width:60px;height:60px;border-radius:100%;background:#ffffff;z-index:9;border:1px solid #dddddd;font-size:1.8rem;font-weight:600;color:#000000;padding:0;outline:0;box-shadow:none;}
.pricingPageWrap .pricingWrap ul.nav-tabs li.nav-item .nav-link.active{background-color:#701A75;color:#ffffff;}
.pricingContent{text-align:center;}
.pricingContent h3{margin:50px auto 0;font-size:2.2rem;font-weight:600;}
.pricingContent p{margin:4px auto 30px;font-size:1.4rem;color:#747474;font-weight:500;letter-spacing:0.2px;}
.pricingContent ul{display:flex;flex-flow:row wrap;justify-content:center;align-items:center;margin:20px auto 0;}
.pricingContent ul li{width:20%;padding:0 10px;margin:0 0 20px;}
.pricingContent ul li .controlCheckbox{display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center;border-radius:8px;padding:0px;position:relative;cursor:pointer;}
.pricingContent ul li .controlCheckbox .icoWrap{margin:0 auto;width:60px;height:60px;background-color:#f6f6f6;border-radius:100%;display:flex;align-items:center;justify-content:center;}
.pricingContent ul li .controlCheckbox .icoWrap svg{width:26px;height:26px;}
.pricingContent.stepTwoData ul li .controlCheckbox .icoWrap{width:50px;height:50px;}
.pricingContent.stepThreeData ul li .controlCheckbox .icoWrap{width:50px;height:50px;display:none;}
.pricingContent ul li .controlCheckbox .dataWrap{text-align:center;width:calc(100% - 0px);margin:6px auto 0;}
.pricingContent.stepTwoData ul li .controlCheckbox .controlIndicator .dataWrap{min-height:64px;}
.pricingContent.stepThreeData ul li .controlCheckbox .dataWrap{min-height:40px;display:flex;align-items:center;justify-content:center;}
.pricingContent ul li .controlCheckbox .dataWrap h2{margin:0;font-size:1.6rem;font-weight:700;color:#000000;letter-spacing:0.2px;}
.pricingContent.stepTwoData ul li .controlCheckbox .dataWrap h2{font-size:1.5rem;}
.pricingContent.stepThreeData ul li .controlCheckbox .dataWrap{margin:0 auto;}
.pricingContent.stepThreeData ul li .controlCheckbox .dataWrap h2{font-size:1.4rem;font-weight:600;line-height:20px;}
.controlCheckbox input{position:absolute;z-index:-1;opacity:0;}
.pricingContent ul li .controlCheckbox .dataWrap span{font-size:1.2rem;font-weight:300;line-height:18px;display:inline-block;margin:4px auto 0;}
.controlIndicator{height:100%;width:100%;background:#ffffff;border-radius:8px;display:flex;flex-flow:column;justify-content:center;align-items:center;padding:20px;border:1px solid #f6f6f6;}
.pricingContent.stepThreeData ul li .controlCheckbox .controlIndicator{padding:10px;}
.pricingContent.stepTwoData .controlIndicator{padding:12px 10px 12px;}
.controlCheckbox input:checked ~ .controlIndicator{background:rgba(255, 255, 255, 1);border:1px solid #701A75;}
.controlCheckbox:hover input:not([disabled]):checked ~ .controlIndicator{background:rgba(255, 255, 255, 1);}
.controlCheckbox input:disabled ~ .controlIndicator{background:#e6e6e6;opacity:0.6;pointer-events:none;}
.controlIndicator:after{content:"";position:absolute;display:none;}
.controlCheckbox input:checked ~ .controlIndicator:after{display:block;}
.controlCheckbox .controlIndicator:after{background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='12' height='12' x='0' y='0' viewBox='0 0 408.576 408.576'><g><path d='M204.288 0C91.648 0 0 91.648 0 204.288s91.648 204.288 204.288 204.288 204.288-91.648 204.288-204.288S316.928 0 204.288 0zm114.176 150.528-130.56 129.536c-7.68 7.68-19.968 8.192-28.16.512L90.624 217.6c-8.192-7.68-8.704-20.48-1.536-28.672 7.68-8.192 20.48-8.704 28.672-1.024l54.784 50.176L289.28 121.344c8.192-8.192 20.992-8.192 29.184 0s8.192 20.992 0 29.184z' fill='%23701A75' opacity='1' data-original='%23701A75'></path></g></svg>");background-repeat:no-repeat;background-size:contain;width:14px;height:14px;position:absolute;right:6px;top:6px;}
.controlCheckbox input:disabled ~ .controlIndicator:after{border-color:#7b7b7b;}
.controlCheckbox input:checked ~ .controlIndicator .icoWrap{background-color:rgba(112, 26, 117, 0.1);}
.controlCheckbox input:checked ~ .controlIndicator .icoWrap svg path,
.controlCheckbox input:checked ~ .controlIndicator .icoWrap svg circle{fill:#701A75;}
.controlCheckbox input:checked ~ .controlIndicator .dataWrap h2,
.controlCheckbox input:checked ~ .controlIndicator .dataWrap span{color:#701A75;}
.pricingContent .ctaWrap{text-align:center;margin:20px auto 0;}
.pricingContent .ctaWrap .btn{background-color:#fbbf24;padding:8px 20px;font-size:1.3rem;font-weight:600;outline:0;box-shadow:none;border:0;border-radius:4px;margin:0 6px;}
.pricingContent.stepEightData ul.formList{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center;}
.pricingContent.stepEightData ul.formList li{width:50%;margin:0 0 16px;padding:0 10px;}
.pricingContent.stepEightData ul.formList li.full{width:100%;}
.pricingContent.stepEightData ul.formList li:last-child{margin:0;}
.pricingContent.stepEightData ul.formList li .inputField label{width:100%;color:#000000;font-size:1.3rem;font-weight:600;letter-spacing:0.2px;line-height:16px;margin:0;text-align:left;}
.pricingContent.stepEightData ul.formList li .inputField input,
.pricingContent.stepEightData ul.formList li .selectField select,
.pricingContent.stepEightData ul.formList li .inputField textarea{width:100%;height:42px;border:1px solid #dddddd;border-radius:6px;padding:2px 10px;font-weight:500;font-size:1.3rem;color:#000000;}
.pricingContent.stepEightData ul.formList li .selectField{position:relative;}
.pricingContent.stepEightData ul.formList li .selectField:after{content:"";background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='512' height='512' x='0' y='0' viewBox='0 0 492 492' style='enable-background:new 0 0 512 512' xml:space='preserve'><g><path d='M198.608 246.104 382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z' fill='%23999999' opacity='1' data-original='%23999999'></path></g></svg>");transform:rotate(-90deg);background-repeat:no-repeat;background-size:contain;width:10px;height:10px;position:absolute;right:8px;top:42px;}
.pricingContent.stepEightData ul.formList li .selectField select{appearance:none;}
.pricingContent.stepEightData ul.formList li .inputField textarea{height:80px;resize:none;}
.pricingContent.stepEightData .btnSubmti{outline:0;border:0;background-color:#701a75;box-shadow:none;border-radius:30px;padding:8px 40px;color:#ffffff;font-weight:500;letter-spacing:0.4px;}

.pricingContent.stepsCommonData ul{flex-flow:column;max-width:620px;padding:0;margin:40px auto;}
.pricingContent.stepsCommonData ul li{width:100%;border-bottom:1px dashed #dddddd;margin:0 0 10px;padding:20px 12px;}
.pricingContent.stepsCommonData ul li .itemWrap{display:flex;flex-flow:row wrap;align-items:center;justify-content:space-between;}
.pricingContent.stepsCommonData ul li .itemWrap h3{width:calc(100% - 310px);text-align:left;font-size:1.6rem;font-weight:500;margin:0;line-height:24px;}
.pricingContent .rangeSliderWrap{padding:0;}
.pricingContent .rangeSliderWrap h4{margin:0 auto 6px;font-size:2.2rem;font-weight:900;color:#000000;}
.pricingContent .rangeSliderWrap input[type="range"]{display:block;-webkit-appearance:none;background-color:#e1e1e1;width:270px;height:10px;border-radius:6px;margin:0 auto;outline:0;box-shadow:none;}
.pricingContent .rangeSliderWrap input[type="range"]::-webkit-slider-thumb{-webkit-appearance:none;background-color:#701A75;width:22px;height:22px;border-radius:100%;border:2px solid #ffffff;cursor:pointer;transition:.3s ease-in-out;}​
.pricingContent .rangeSliderWrap input[type="range"]::-webkit-slider-thumb:hover{background-color:#ffffff;border:2px solid #fbbf24;}
.pricingContent .rangeSliderWrap input[type="range"]::-webkit-slider-thumb:active{transform:scale(1.2);}
.aiMlWrap.commonCotentWrap.AIChatbotsWrap{background-color:#fafafa;text-align:center;padding:70px 0 50px;}
.AIChatbotsWrap .titleWrap h2{margin:0 auto 10px;}
.AIChatbotsWrap .titleWrap h3{margin:0 0 5px;font-weight:600;}
.AIChatbotsWrap .titleWrap span{font-size:1.6rem;letter-spacing:0.2px;display:inline-block;margin:0 auto 10px;color:#701a75;}

.cookiesWrap{position:fixed;left:50%;transform:translateX(-50%);width:100%;bottom:0;z-index:99;}
.cookiesWrap .dataWrap{padding:16px 26px;background-color:#701a75f2;width:100%;position:relative;}
.cookiesWrap .dataWrap p{color:#ffffff;margin:0 auto;text-align:center;font-size:1.5rem;letter-spacing:0.2px;font-weight:400;}
.cookiesWrap .dataWrap .ctaWrap{text-align:center;margin:10px auto 0;}
.cookiesWrap .dataWrap .ctaWrap .btn{box-shadow:none;border:0;outline:0;background-color:rgba(255, 255, 255, 0.8);padding:6px 20px;
margin:0 4px;border-radius:1px;font-size:1.2rem;font-weight:600;color:#701a75;letter-spacing:0.2px;}

.empowerIndWrap{width:100%;margin:0 auto;padding:20px 0 40px;}
.empowerIndWrap.AIECommerce{padding:60px 0 40px;}
.empowerIndWrap .tileWrap{text-align:center;margin:0 auto 40px;}
.empowerIndWrap .tileWrap h2{color:#000000;font-weight:800;font-size:2.8rem;}
.empowerIndWrap .itemOuter{padding:0 10px;}
.empowerIndWrap .itemWrap{border-radius:8px;padding:26px 12px;}
.empowerIndWrap .itemWrap.clr1{background-image:linear-gradient(to bottom,rgba(156,238,189,.41) 0,rgba(170,200,179,.41) 100%);}
.empowerIndWrap .itemWrap.clr2{background-image:linear-gradient(to bottom,rgba(156,205,238,.41) 0,rgba(123,157,170,.41) 100%);}
.empowerIndWrap .itemWrap.clr3{background-image:linear-gradient(to bottom,rgba(203,156,238,.41) 0,rgba(197,130,214,.41) 100%);}
.empowerIndWrap .itemWrap.clr4{background-image:linear-gradient(to bottom,rgba(238,238,156,.41) 0,rgba(206,210,130,.41) 100%);}
.empowerIndWrap .itemWrap.clr5{background-image:linear-gradient(to bottom,rgba(238,156,156,.41) 0,rgba(238,156,156,.41) 1%,rgba(239,171,173,.41) 100%);}
.empowerIndWrap .itemWrap.clr6{background-image:linear-gradient(to bottom, rgb(140 140 255 / 41%) 0, rgb(85 77 182 / 41%) 100%);}
.empowerIndWrap .itemWrap .icoWrap{text-align:center;margin:0 auto 16px;}
.empowerIndWrap .itemWrap .dataWrap{text-align:center;}
.empowerIndWrap .itemWrap .dataWrap h2{color:#000000;font-weight:700;font-size:1.6rem;line-height:28px;margin:0 auto;}
.empowerIndWrap .itemWrap .dataWrap p{margin:0 auto;color:#3d3d3d;font-size:1.5rem;line-height:26px;letter-spacing:0.1px;min-height:170px;}
.empowerIndWrap.AIECommerce .itemWrap .dataWrap p{min-height:180px;}
.empowerIndWrap .itemWrap .dataWrap p strong{font-weight:600;}
.empowerIndWrap .slick-dots{display:flex!important;justify-content:center;align-items:center;}
.empowerIndWrap .slick-dots li{padding:0!important;margin:0;width:auto;}
.empowerIndWrap .slick-dots li button{width:6px;height:6px;margin:20px 6px 0px;border-radius:30px;display:inline-block;border-top-right-radius:0;background-color:#b9b9b9;-webkit-transition:0.3s all ease-in-out;transition:0.3s all ease-in-out;color:transparent;}
.empowerIndWrap .slick-dots li.slick-active button{width:35px;background-color:#d4bbd6;}
.empowerIndWrap .slick-dots li button:before{content:none;}

.howAiPoweredAppBooking{width:100%;margin:0 auto;padding:60px 0 30px;}
.howAiPoweredAppBooking .ttlWrap{text-align:center;margin:0 auto 40px;}
.howAiPoweredAppBooking .ttlWrap h2{font-size:2.8rem;line-height:40px;font-weight:800;}
.howAiPoweredAppBooking .itemWrap{background-color:#f7f8ff;padding:16px;margin:0 auto 16px;}
.howAiPoweredAppBooking .itemWrap .icoWrap{text-align:center;margin:0 auto 12px;display:flex;align-items:center;justify-content:center;width:62px;position:relative;}
.howAiPoweredAppBooking .itemWrap .icoWrap:after{content:"";width:32px;height:32px;border-radius:100%;position:absolute;right:-6px;top:-6px;z-index:1;}
.howAiPoweredAppBooking .itemWrap .icoWrap.icoWrap1:after{background-color:#9ceebd;}
.howAiPoweredAppBooking .itemWrap .icoWrap.icoWrap2:after{background-color:#9ccdee;}
.howAiPoweredAppBooking .itemWrap .icoWrap.icoWrap3:after{background-color:#cb9cee;}
.howAiPoweredAppBooking .itemWrap .icoWrap.icoWrap4:after{background-color:#dcdc30;}
.howAiPoweredAppBooking .itemWrap .icoWrap.icoWrap5:after{background-color:#ff9393;}
.howAiPoweredAppBooking .itemWrap .icoWrap.icoWrap6:after{background-color:#7a7ae0;}
.howAiPoweredAppBooking .itemWrap .icoWrap svg{position:relative;z-index:2;}
.howAiPoweredAppBooking .itemWrap .dataWrap{text-align:center;}
.howAiPoweredAppBooking .itemWrap .dataWrap h2{color:#000000;font-weight:700;font-size:1.6rem;line-height:28px;margin:0 auto 4px;}
.howAiPoweredAppBooking .itemWrap .dataWrap p{margin:0 auto;color:#3d3d3d;font-size:1.5rem;line-height:26px;letter-spacing:0.1px;min-height:120px}
.howAiPoweredAppBooking .itemWrap .dataWrap p strong{font-weight:600;}

.webRevampIndicat.empowerIndWrap{background-color:#fafafa;padding:40px 0 80px;}
.webRevampIndicat.empowerIndWrap .tileWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 auto;}
.webRevampTrendsWrap.staffAugBenefitsWrap ul li{width:25%;}
.webBestPractWrap.staffOnBoardWrap ul li .itemWrap .dataWrap h2{line-height:22px;margin:0 auto 2px;}
.webRevampStepsWrap.ProjectSecWrap{padding:0;}
.webRevampStepsWrap.ProjectSecWrap .dataWrap>h2,
.webRevampToolListWrap.ProjectSecWrap .dataWrap>h2{margin:0 auto;}
.ProjectSecWrap .dataWrap ul li .dataWrap h3{color:#000000;font-size:1.8rem;font-weight:900;margin:0 0 10px;position:relative;padding:0 0 4px 0;}
.ProjectSecWrap .dataWrap ul li .dataWrap h3:before{content:"";width:40px;height:2px;position:absolute;background-color:#ffc810;bottom:0;left:0;}
.ProjectSecWrap .dataWrap ul li .dataWrap h4{color:#000000;font-size:1.6rem;font-weight:700;margin:0 0 10px;position:relative;padding:0 0 4px 0;}
.webRevampToolConclusion{background-color:#fafafa;}
.webUiKitWrap.howAiPoweredAppBooking{padding:0;}
.webUiKitWrap.howAiPoweredAppBooking .itemWrap{padding:30px 20px;}
.webUiKitWrap.howAiPoweredAppBooking .itemWrap .dataWrap p{min-height:90px;} 
.designTrendUIKitWrap.empowerIndWrap .itemWrap .dataWrap{min-height:180px;}
.designTrendUIKitWrap.empowerIndWrap .itemWrap .dataWrap p{min-height:1px;}
.powerBiTakeWrap.empowerIndWrap .itemWrap .dataWrap p{min-height:100px;}
.empowerIndWrap .tileWrap p,
.howAiPoweredAppBooking .ttlWrap p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0;}
.DrivingConstructBenefitsWrap.staffAugBenefitsWrap{margin:0 auto;}
.DrivingConstructBenefitsWrap.staffAugBenefitsWrap .dataWraps{margin:20px auto 0;}
.DrivingConstructBenefitsWrap.staffAugBenefitsWrap .dataWraps p{color:#0F172A;font-size:1.6rem;line-height:30px;letter-spacing:0.2px;margin:0 auto;text-align:center;}
.DrivingConstructWrap.servCaseStudyWrap{padding:50px 0 60px;}
.DrivingConstructWrap.servCaseStudyWrap ul li{position:inherit;top:auto;margin:0 auto;height:auto;}
.DrivingConstructWrap.servCaseStudyWrap .itemWrap{margin:0 auto;padding:40px 30px;}
.DrivingConstructStepsWrap.staffOnBoardWrap ul{margin:10px auto 0;}
.DrivingConstructStepsWrap.staffOnBoardWrap ul li .itemWrap .dataWrap{min-height:252px;}
.DrivingConstructStepsWrap.staffOnBoardWrap ul li .itemWrap .dataWrap h2{line-height:22px;}
.constructionIndustWrap.staffAugBenefitsWrap{margin:0 auto;background-color:#ffffff;}
.constructionIndustWrap.staffAugBenefitsWrap ul li{width:33.33%;}
.businessAnaWrap.ProjectSecWrap .dataWrap ul li .dataWrap h2{font-size:2.4rem;}
.businessAnaAiFeatWrap.staffAugBenefitsWrap{margin:0 auto;}
.businessAnaAiFeatWrap.staffAugBenefitsWrap ul li .itemWrap .dataWrap{min-height:204px;}
.businessAnaAiFeatWrap.staffAugBenefitsWrap ul li .itemWrap .dataWrap h2{line-height:22px;}
